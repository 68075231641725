import { Tooltip } from '@chakra-ui/react'
import { analytics } from 'analytics'
import { isBoolean, isEqual, isNumber, orderBy, size } from 'lodash'
import { useEffect, useMemo, useReducer, useState } from 'react'
import { NumericFormat } from 'react-number-format'

import { AssetClass, BenchmarkType } from 'enums'
import { InvestmentTemplate, Portfolio, PortfolioLinkType } from 'types'
import { ErrorObject } from 'types/generalTypes'
import {
  PortfolioFormPayload,
  PortfolioFormStateFilled,
} from 'types/portfolioTypes'

import { paveApi } from 'api'
import { Box, Dropdown, Text, TextInput } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import {
  AutomationPopOver,
  DropdownWithTags,
  RequiredAssetsTable,
} from 'components/partials'
import AutomationConfirmDialog from 'components/partials/CreatePortfolio/AutomationConfirmDialog'
import ExcludedAssetsDropdown from 'components/partials/CreatePortfolio/ExcludedAssetsDropdown'
import ModalLoader from 'components/partials/ModalLoader'
import {
  evaluateImpactedTaxOptimizationFields,
  evaluateTemplatePreferences,
  portfolioFormReducer,
  setAssetClass,
  setAssets,
  setAutomation,
  setBenchmarkId,
  setBuyListId,
  setEnableTaxOptimization,
  setExcludedAssetClasses,
  setExcludedAssets,
  setExcludedIndustries,
  setExcludedSectors,
  setFractionalShares,
  setInvestmentTemplateId,
  setStrategy,
  setTargetAssetCount,
  setTargetCashWeightPercent,
} from 'components/reducers/portfolioFormReducer'
import {
  DEFAULT_TARGET_ASSET_COUNT,
  DEFAULT_TARGET_CASH_WEIGHT_PLACEHOLDER,
  MINIMUM_VALID_TARGET_CASH_WEIGHT_PERCENT,
} from 'constants/portfolio.constants'
import { portfolioFormOptions } from 'constants/portfolio.constants'
import { PORTFOLIO_INVALID_FORM_DATA_SUBMITTED } from 'constants/track.constants'
import {
  INCORRECT_SELECTION,
  INVALID_PERFORMANCE_PRIORITY,
  INVALID_PORTFOLIO_VALUE,
  INVALID_TARGET_CASH_WEIGHT,
  PORTFOLIO_NEEDS_VALUE,
  REQUIRED_FIELD,
} from 'constants/validation.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { useAppSelector } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { isNullish, jsonClone } from 'utils/generalUtils'
import {
  formatToPortfolioForm,
  formatToPortfolioFormPayload,
  getDefaultPerformancePriority,
  isTemplateField,
} from 'utils/portfolioFormUtil'
import {
  calculatePortfolioValue,
  getPortfolioLinkType,
  isAutomationSupportedPortfolio,
  isManualPortfolio,
} from 'utils/portfolioUtil'

import { DropdownOptionString } from '../DropdownWithTags'
import InvestmentTemplatePopOver from '../PopOver/InvestmentTemplatePopOver'
import BuyListPopOver from '../Portfolio/PortfolioPreferences/BuyListPopOver'
import TargetCashAllocationPopOver from '../Portfolio/PortfolioPreferences/TargetCashAllocationPopOver'
import { FactorTiltsFormSection } from './FactorTiltsFormSection'
import MultiAssetClassStrategySelector from './MultiAssetClassStrategySelector'

type Props = {
  initialPortfolio: Portfolio
  isNewPortfolio?: boolean
  showLoader?: boolean
  handleSubmitForm: (portfolio: PortfolioFormPayload) => void
}

const {
  assetClassOptions,
  fractionalTradingOptions,
  enableTaxOptimizationOptions,
} = portfolioFormOptions

export default function PortfolioForm({
  initialPortfolio,
  isNewPortfolio = false,
  showLoader,
  handleSubmitForm,
}: Props) {
  const initialPortfolioFormData = useMemo(() => {
    return formatToPortfolioForm(initialPortfolio, isNewPortfolio)
  }, [initialPortfolio, isNewPortfolio])
  const [formState, dispatch] = useReducer(
    portfolioFormReducer,
    initialPortfolioFormData
  )
  const { managementOrganizationId } = useAppSelector((state) => state.user)

  const { showErrorToast, showInfoToast } = useCustomToast()
  const linkType = getPortfolioLinkType(initialPortfolio)
  const isAutomationSupported = isAutomationSupportedPortfolio(initialPortfolio)

  const isLinkedPortfolio = linkType !== PortfolioLinkType.None
  const { data: benchmarks } = paveApi.useGetBenchmarksQuery()
  const { data: buyLists } = paveApi.useGetBuyListNamesQuery()
  const { data: templatesResponse, isLoading: isTemplateQueryLoading } =
    paveApi.useGetManagementOrganizationInvestmentTemplatesQuery(
      managementOrganizationId || '',
      {
        skip: !managementOrganizationId,
        refetchOnMountOrArgChange: true,
      }
    )
  const { data: sectors } = paveApi.useGetSectorsQuery()
  const { data: assets } = paveApi.useGetAssetsQuery()

  const [formErrors, setFormErrors] = useState<ErrorObject>({})
  const isMultiAssetClass =
    formState.assetClass === AssetClass.MULTI_ASSET_CLASS

  const [isAutomationConfirmDialogOpen, setIsAutomationConfirmDialogOpen] =
    useState<boolean>(false)

  const benchmarkOptions: DropdownOptionString[] = useMemo(() => {
    return orderBy(
      benchmarks
        ?.filter((benchmark) => benchmark.type === BenchmarkType.STOCK)
        .map(({ name, id }) => ({
          label: name,
          value: `${id}`,
        })) || [],
      'label'
    )
  }, [benchmarks])

  const [selectedInvestmentTemplate, setSelectedInvestmentTemplate] =
    useState<InvestmentTemplate | null>(null)
  const [pendingTemplate, setPendingTemplate] =
    useState<InvestmentTemplate | null>(null)

  const buyListOptions = (buyLists ?? []).map(({ displayName, id }) => {
    return { label: displayName, value: id }
  }) as DropdownOptionString[]

  const templateOptions: DropdownOptionString[] = useMemo(() => {
    let templates = templatesResponse?.investmentPreferencesTemplates
    return (
      templates?.map(({ displayName, id, investmentPreferences }) => ({
        label: displayName,
        value: `${id}`,
        isDisabled:
          (!isAutomationSupported &&
            investmentPreferences.automation === true) ||
          (!isLinkedPortfolio &&
            investmentPreferences.enableTaxOptimization === true),
      })) || []
    )
  }, [templatesResponse, isAutomationSupported, isLinkedPortfolio])

  useEffect(() => {
    if (templatesResponse) {
      const selectedTemplate =
        templatesResponse?.investmentPreferencesTemplates.find(
          ({ id }) => id === formState.investmentTemplateId
        ) ?? null

      setSelectedInvestmentTemplate(selectedTemplate)
    }
  }, [formState.investmentTemplateId, templatesResponse])

  const sectorOptions: DropdownOptionString[] = []
  const industryOptions: DropdownOptionString[] = []
  const assetsOptions: DropdownOptionString[] = []
  const assetClassesOptions: DropdownOptionString[] = []
  const assetClassSet = new Set()

  sectors?.forEach((sector) => {
    sectorOptions.push({
      label: sector.name,
      value: sector.name,
    })
    sector.industries.forEach((industry) => {
      industryOptions.push({
        label: industry.name,
        value: industry.name,
      })
    })
  })

  assets?.forEach((a) => {
    assetsOptions.push({ label: a.name, value: `${a.symbol}` })

    if (a.class) {
      assetClassSet.add(a.class)
    }
  })

  Array.from(assetClassSet).forEach((a) => {
    assetClassesOptions.push({ label: String(a), value: String(a) })
  })

  const onAutomationConfirmDialog = () => {
    if (pendingTemplate) {
      dispatch(setInvestmentTemplateId(pendingTemplate.id))
      dispatch(evaluateTemplatePreferences(pendingTemplate))
      setSelectedInvestmentTemplate(pendingTemplate)
      setPendingTemplate(null)
    }
    dispatch(setAutomation(!formState.automation))
  }

  // TODO ADV-29: Investigate and refactor the validator object and function
  const validateData = () => {
    const defaultPerformancePriority = getDefaultPerformancePriority(formState)
    // TODO ADV-29: Update type interface
    let newErrors: ErrorObject = {}
    // asset class
    if (!formState.assetClass) {
      newErrors.assetClass = REQUIRED_FIELD
    }

    if (
      isNumber(formState.targetCashWeightPercent) &&
      (formState.targetCashWeightPercent <
        MINIMUM_VALID_TARGET_CASH_WEIGHT_PERCENT ||
        formState.targetCashWeightPercent > 100)
    ) {
      newErrors.targetCashWeightPercent = INVALID_TARGET_CASH_WEIGHT
    }

    // benchmark
    if (formState.assetClass === AssetClass.EQUITY) {
      if (!formState.benchmarkId) {
        newErrors.benchmarkId = REQUIRED_FIELD
      }
    } else if (!formState.multiAssetClassStrategy) {
      newErrors.strategy = REQUIRED_FIELD
    }
    // automation
    if (isNullish(formState.automation)) {
      newErrors.automation = REQUIRED_FIELD
    }
    // fractional shares
    if (isNullish(formState.fractionalShares)) {
      newErrors.fractionalShares = REQUIRED_FIELD
    }
    // tax optimization
    if (isNullish(formState.enableTaxOptimization) && isLinkedPortfolio) {
      newErrors.enableTaxOptimization = REQUIRED_FIELD
    } else if (
      formState.enableTaxOptimization === true &&
      isManualPortfolio(initialPortfolio)
    ) {
      newErrors.enableTaxOptimization = INCORRECT_SELECTION
    }

    if (!formState.isThirdPartyManaged) {
      // available cash
      if (
        isNullish(formState.availableCash) ||
        formState.availableCash === null
      ) {
        newErrors.availableCash = REQUIRED_FIELD
      }

      // performance priority + factor tilts
      if (isNullish(formState.performancePriority)) {
        newErrors.performancePriority = REQUIRED_FIELD
      }
      // factor tilts
      if (
        formState?.factorTilts?.length &&
        formState.performancePriority !== defaultPerformancePriority
      ) {
        newErrors.performancePriority = INVALID_PERFORMANCE_PRIORITY
      }
    }

    // portoflio value
    const portfolioValue = calculatePortfolioValue(
      formState.availableCash,
      formState.assets
    )
    if (portfolioValue <= 0) newErrors.portfolioValue = PORTFOLIO_NEEDS_VALUE

    setFormErrors(newErrors)
    return {
      isValidData: size(newErrors) === 0,
      errors: newErrors,
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const { isValidData, errors } = validateData()
    const isFormModified = !isEqual(
      jsonClone(initialPortfolioFormData),
      jsonClone(formState)
    )

    if (!isValidData) {
      showErrorToast('Please complete the form to continue')
      analytics.track(PORTFOLIO_INVALID_FORM_DATA_SUBMITTED, {
        errors: errors,
      })
    } else if (!isFormModified) {
      showInfoToast('No changes detected.', 'Please try again.')
    } else {
      const formStateInput = formState as PortfolioFormStateFilled
      // TODO: Refactor out formatToPortfolioFormPayload, no longer needed
      let portfolioPayload = formatToPortfolioFormPayload(formStateInput)
      if (initialPortfolio.investmentPreference?.withdrawalCashRequest) {
        portfolioPayload.investmentPreference.withdrawalCashRequest =
          initialPortfolio.investmentPreference?.withdrawalCashRequest
      }
      handleSubmitForm(portfolioPayload)
    }
  }

  return (
    <>
      {(showLoader || isTemplateQueryLoading) && <ModalLoader />}
      <form id="portfolio-form" onSubmit={handleSubmit}>
        {templateOptions.length > 0 && (
          <Box marginBottom={SPACE.sp24}>
            <Box marginBottom={SPACE.sp8}>
              <Text
                color={COLOR.coolGray600}
                fontSize={FONT_SIZE.fs16}
                fontWeight={700}
              >
                Template
              </Text>
            </Box>
            <Box>
              <Dropdown
                id="investment-template"
                label="Investment Template"
                options={templateOptions}
                onChange={(option: any) => {
                  if (option.value === formState.investmentTemplateId) return

                  const newTemplate =
                    templatesResponse?.investmentPreferencesTemplates?.find(
                      ({ id }) => id === option.value
                    )

                  if (newTemplate) {
                    const templateAutomation =
                      newTemplate?.investmentPreferences?.automation
                    const oldAutomation = formState.automation

                    // Show confirm dialog if automation is a boolean and is different than the current automation
                    if (
                      isBoolean(templateAutomation) &&
                      templateAutomation !== oldAutomation
                    ) {
                      setPendingTemplate(newTemplate)
                      setIsAutomationConfirmDialogOpen(true)
                    } else {
                      dispatch(setInvestmentTemplateId(option.value))
                      dispatch(evaluateTemplatePreferences(newTemplate))
                      setSelectedInvestmentTemplate(newTemplate)
                    }
                  }
                }}
                popOver={<InvestmentTemplatePopOver />}
                value={formState.investmentTemplateId ?? null}
                isClearable={true}
                clearValueLabel="Remove Template"
                onClearValue={() => {
                  setSelectedInvestmentTemplate(null)
                  dispatch(setInvestmentTemplateId(null))
                }}
                placeholder="Select"
              />
            </Box>
          </Box>
        )}

        <Box marginBottom={SPACE.sp24}>
          <Box marginBottom={SPACE.sp8}>
            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs16}
              fontWeight={700}
            >
              Trading
            </Text>
          </Box>
          <FlexBox gap={SPACE.sp12}>
            <Box flex={1}>
              <Dropdown
                id="automation"
                label="Automation"
                popOver={<AutomationPopOver />}
                isDisabled={
                  !isAutomationSupported ||
                  isTemplateField(selectedInvestmentTemplate, 'automation')
                }
                isSearchable={false}
                onChange={({ value }: any) => {
                  if (value !== formState.automation) {
                    setIsAutomationConfirmDialogOpen(true)
                  }
                }}
                options={fractionalTradingOptions}
                placeholder="Select"
                value={formState.automation}
                variant={
                  isTemplateField(selectedInvestmentTemplate, 'automation')
                    ? 'template'
                    : 'base'
                }
              />
              {!isLinkedPortfolio && (
                <Tooltip
                  label="Automation is disabled for manually inputted portfolios"
                  placement="auto-start"
                >
                  <Text fontSize="xs" as="i">
                    Why can I not select this option?
                  </Text>
                </Tooltip>
              )}
            </Box>
            <Box flex={1}>
              <Dropdown
                id="fractional-shares"
                isDisabled={isTemplateField(
                  selectedInvestmentTemplate,
                  'fractionalShares'
                )}
                isSearchable={false}
                label="Fractional Shares"
                onChange={({ value }: any) => {
                  dispatch(setFractionalShares(value))
                }}
                options={fractionalTradingOptions}
                placeholder="Select"
                value={formState.fractionalShares}
                variant={
                  isTemplateField(
                    selectedInvestmentTemplate,
                    'fractionalShares'
                  )
                    ? 'template'
                    : 'base'
                }
              />
            </Box>
            <Box flex={1}>
              <Dropdown
                id="tax-optimization"
                errorMessage={formErrors.enableTaxOptimization}
                isDisabled={
                  !isLinkedPortfolio ||
                  isTemplateField(
                    selectedInvestmentTemplate,
                    'enableTaxOptimization'
                  )
                }
                isSearchable={false}
                label="Tax Optimization"
                onChange={(option: any) => {
                  dispatch(setEnableTaxOptimization(option.value))
                  dispatch(evaluateImpactedTaxOptimizationFields())
                }}
                options={enableTaxOptimizationOptions}
                placeholder="Select"
                value={enableTaxOptimizationOptions.find(
                  ({ value }) => value === formState.enableTaxOptimization
                )}
                variant={
                  isTemplateField(
                    selectedInvestmentTemplate,
                    'enableTaxOptimization'
                  )
                    ? 'template'
                    : 'base'
                }
              />
              {!isLinkedPortfolio && (
                <Tooltip
                  label="Tax Optimization is disabled for manually inputted portfolios"
                  placement="auto-start"
                >
                  <Text fontSize="xs" as="i">
                    Why can I not select this option?
                  </Text>
                </Tooltip>
              )}
            </Box>
          </FlexBox>
        </Box>

        <Box marginBottom={SPACE.sp24}>
          <Box marginBottom={SPACE.sp8}>
            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs16}
              fontWeight={700}
            >
              Strategy
            </Text>
          </Box>
          <FlexBox gap={SPACE.sp12}>
            <Box flex={1}>
              <Dropdown
                id="asset-class"
                errorMessage={formErrors?.assetClass}
                label="Asset Class"
                isDisabled={isTemplateField(
                  selectedInvestmentTemplate,
                  'assetClass'
                )}
                isSearchable={false}
                value={assetClassOptions.find(
                  ({ value }) => value === formState.assetClass
                )}
                options={assetClassOptions}
                onChange={(option: any) => {
                  dispatch(setAssetClass(option.value))
                  dispatch(setBenchmarkId(null))
                  dispatch(setStrategy(null))
                }}
                variant={
                  isTemplateField(selectedInvestmentTemplate, 'assetClass')
                    ? 'template'
                    : 'base'
                }
              />
            </Box>
            {isMultiAssetClass ? (
              <Box flex={1}>
                <MultiAssetClassStrategySelector
                  dispatch={dispatch}
                  formErrors={formErrors}
                  formState={formState}
                  selectedInvestmentTemplate={selectedInvestmentTemplate}
                  variant={
                    isTemplateField(selectedInvestmentTemplate, 'assetClass')
                      ? 'template'
                      : 'base'
                  }
                />
              </Box>
            ) : (
              <Box flex={1}>
                <Dropdown
                  id="benchmark"
                  errorMessage={formErrors?.benchmarkId}
                  label="Benchmark"
                  value={formState.benchmarkId}
                  isDisabled={isTemplateField(
                    selectedInvestmentTemplate,
                    'benchmarkId'
                  )}
                  isSearchable={true}
                  onChange={(option: any) =>
                    dispatch(setBenchmarkId(option.value))
                  }
                  options={benchmarkOptions}
                  variant={
                    isTemplateField(selectedInvestmentTemplate, 'benchmarkId')
                      ? 'template'
                      : 'base'
                  }
                />
              </Box>
            )}
          </FlexBox>
        </Box>

        <Box marginBottom={SPACE.sp24}>
          <Box marginBottom={SPACE.sp8}>
            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs16}
              fontWeight={700}
            >
              Constraints
            </Text>
          </Box>

          {isMultiAssetClass ? (
            <FlexBox gap={SPACE.sp12}>
              <Box flex={1}>
                <DropdownWithTags
                  id="excluded-asset-classes"
                  isDisabled={isTemplateField(
                    selectedInvestmentTemplate,
                    'excludedAssetClasses'
                  )}
                  options={assetClassesOptions}
                  tagColor="red"
                  label="Excluded Asset Classes"
                  placeholder="Search by asset class"
                  onChange={(value) =>
                    dispatch(setExcludedAssetClasses(value.sort()))
                  }
                  value={formState.excludedAssetClasses}
                  variant={
                    isTemplateField(
                      selectedInvestmentTemplate,
                      'excludedAssetClasses'
                    )
                      ? 'template'
                      : 'base'
                  }
                />
              </Box>
              <Box flex={1}>
                <ExcludedAssetsDropdown
                  isDisabled={isTemplateField(
                    selectedInvestmentTemplate,
                    'excludedAssets'
                  )}
                  assetsOptions={assetsOptions}
                  onChange={(value) =>
                    dispatch(setExcludedAssets(value.sort()))
                  }
                  excludedAssets={formState.excludedAssets}
                  variant={
                    isTemplateField(
                      selectedInvestmentTemplate,
                      'excludedAssets'
                    )
                      ? 'template'
                      : 'base'
                  }
                />
              </Box>
            </FlexBox>
          ) : (
            <>
              <FlexBox gap={SPACE.sp12}>
                <Box flex={1}>
                  <DropdownWithTags
                    id="excluded-sectors"
                    isDisabled={isTemplateField(
                      selectedInvestmentTemplate,
                      'excludedSectors'
                    )}
                    options={sectorOptions}
                    tagColor="red"
                    label="Excluded Sectors"
                    placeholder="Search by sector"
                    onChange={(value) =>
                      dispatch(setExcludedSectors(value.sort()))
                    }
                    value={formState.excludedSectors}
                    variant={
                      isTemplateField(
                        selectedInvestmentTemplate,
                        'excludedSectors'
                      )
                        ? 'template'
                        : 'base'
                    }
                  />
                </Box>
                <Box flex={1}>
                  <DropdownWithTags
                    id="excluded-industries"
                    isDisabled={isTemplateField(
                      selectedInvestmentTemplate,
                      'excludedIndustries'
                    )}
                    options={industryOptions}
                    tagColor="red"
                    label="Excluded Industries"
                    placeholder="Search by industry"
                    onChange={(value) =>
                      dispatch(setExcludedIndustries(value.sort()))
                    }
                    value={formState.excludedIndustries}
                    variant={
                      isTemplateField(
                        selectedInvestmentTemplate,
                        'excludedIndustries'
                      )
                        ? 'template'
                        : 'base'
                    }
                  />
                </Box>
              </FlexBox>
              <Box flex={1} marginTop={SPACE.sp12}>
                <ExcludedAssetsDropdown
                  isDisabled={isTemplateField(
                    selectedInvestmentTemplate,
                    'excludedAssets'
                  )}
                  assetsOptions={assetsOptions}
                  onChange={(value) =>
                    dispatch(setExcludedAssets(value.sort()))
                  }
                  excludedAssets={formState.excludedAssets}
                  variant={
                    isTemplateField(
                      selectedInvestmentTemplate,
                      'excludedAssets'
                    )
                      ? 'template'
                      : 'base'
                  }
                />
              </Box>
            </>
          )}
        </Box>

        <Box marginBottom={SPACE.sp24}>
          <FactorTiltsFormSection
            dispatch={dispatch}
            formErrors={formErrors}
            formState={formState}
            selectedInvestmentTemplate={selectedInvestmentTemplate}
          />
        </Box>

        <Box>
          <Box marginBottom={SPACE.sp8}>
            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs16}
              fontWeight={700}
            >
              Assets
            </Text>
          </Box>

          {formErrors.portfolioValue && (
            <Box paddingBottom="xl">
              <Text
                color="status.error"
                fontWeight="medium"
                fontSize="xs"
                lineHeight="xxs"
                mt="sm"
                mb={0}
                as="p"
              >
                {INVALID_PORTFOLIO_VALUE}
              </Text>
            </Box>
          )}

          <FlexBox gap={SPACE.sp12}>
            <Box flex={1}>
              <FlexBox
                flexDirection="row"
                gap={SPACE.sp10}
                paddingBottom={SPACE.sp6}
              >
                <Text
                  color={COLOR.coolGray600}
                  fontSize={FONT_SIZE.fs14}
                  fontWeight={500}
                  marginBottom={SPACE.sp4}
                >
                  Target Asset Count
                </Text>
              </FlexBox>
              <TextInput
                id="target-asset-count"
                errorMessage={formErrors.targetAssetCount}
                min={0}
                type="number"
                disabled={isTemplateField(
                  selectedInvestmentTemplate,
                  'targetAssetCount'
                )}
                placeholder={String(DEFAULT_TARGET_ASSET_COUNT)}
                step={1}
                value={formState.targetAssetCount ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(setTargetAssetCount(parseInt(e.target.value)))
                }
                variant={
                  isTemplateField(
                    selectedInvestmentTemplate,
                    'targetAssetCount'
                  )
                    ? 'template'
                    : 'base'
                }
              />
            </Box>
            <Box flex={1}>
              <FlexBox
                flexDirection="row"
                gap={SPACE.sp10}
                paddingBottom={SPACE.sp6}
              >
                <Text
                  color={COLOR.coolGray600}
                  fontSize={FONT_SIZE.fs14}
                  fontWeight={500}
                  marginBottom={SPACE.sp4}
                >
                  Target Cash Allocation
                </Text>
                <TargetCashAllocationPopOver />
              </FlexBox>
              <NumericFormat
                id="target-cash-allocation"
                errorMessage={formErrors.targetCashWeightPercent}
                allowNegative={false}
                allowLeadingZeros={false}
                decimalScale={2}
                maxLength={6}
                suffix="%"
                customInput={TextInput}
                disabled={isTemplateField(
                  selectedInvestmentTemplate,
                  'targetCashWeight'
                )}
                placeholder={String(DEFAULT_TARGET_CASH_WEIGHT_PLACEHOLDER)}
                step={1}
                value={formState.targetCashWeightPercent ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(
                    setTargetCashWeightPercent(parseFloat(e.target.value))
                  )
                }
                variant={
                  isTemplateField(
                    selectedInvestmentTemplate,
                    'targetCashWeight'
                  )
                    ? 'template'
                    : 'base'
                }
              />
            </Box>
            {/* TODO: Only display the form if all queries are successful */}
            {buyListOptions?.length > 0 && (
              <Box flex={1}>
                <Dropdown
                  id="buy-list"
                  isClearable={
                    !isTemplateField(selectedInvestmentTemplate, 'buyListId')
                  }
                  isDisabled={isTemplateField(
                    selectedInvestmentTemplate,
                    'buyListId'
                  )}
                  isSearchable={false}
                  label="Buy List"
                  onChange={(option: any) => {
                    dispatch(setBuyListId(option.value))
                  }}
                  popOver={<BuyListPopOver />}
                  onClearValue={() => {
                    dispatch(setBuyListId(null))
                  }}
                  options={buyListOptions}
                  placeholder="Select"
                  value={formState.buyListId}
                  variant={
                    isTemplateField(selectedInvestmentTemplate, 'buyListId')
                      ? 'template'
                      : 'base'
                  }
                />
              </Box>
            )}
          </FlexBox>

          <NumericFormat
            id="available-cash"
            label="Available Cash"
            placeholder="$0.00"
            customInput={TextInput}
            value={formState.availableCash}
            displayType="input"
            thousandSeparator={true}
            decimalScale={2}
            disabled={true}
            prefix="$"
          />

          <RequiredAssetsTable
            assets={orderBy(formState.assets, 'symbol')}
            setAssets={(value) => dispatch(setAssets(value))}
            readOnly={true}
          />
        </Box>
      </form>
      <AutomationConfirmDialog
        isAutomationConfirmDialogOpen={isAutomationConfirmDialogOpen}
        setIsAutomationConfirmDialogOpen={setIsAutomationConfirmDialogOpen}
        onConfirm={onAutomationConfirmDialog}
        isAutomationOn={formState.automation}
      />
    </>
  )
}
