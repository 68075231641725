import { Box, Button, ConfirmDialog, Text } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  handleTradeImmediately: () => void
  handleTradeOnCadence: () => void
  formType?: 'template' | 'portfolio'
}

const descriptionsForFormType = {
  template: [
    'Once this template is saved, linked portfolio investment preferences will be updated and any suggested trades will be cleared. Suggested trades will then be generated in the background, which could take up to a few hours.',
    'You can choose to have Pave Pro trade the linked portfolios’ new suggestions immediately or on their existing monthly trade cadence.',
    'If automation is on and immediate trading is selected, linked portfolios will be scheduled to trade during the following trading day.',
  ],
  portfolio: [
    'You can choose to have Pave Pro trade the portfolio’s new suggestions immediately or on the portfolio’s existing monthly trade cadence.',
    'You can choose to have Pave Pro trade the portfolio’s new suggestions immediately or on the portfolio’s existing monthly trade cadence.',
    'If automation is on and immediate trading is selected, the portfolio will be scheduled to trade during the following trading day.',
  ],
}

function TradePreferenceDialog({
  isOpen,
  setIsOpen,
  handleTradeImmediately,
  handleTradeOnCadence,
  formType = 'portfolio',
}: Props) {
  const closeDialog = () => setIsOpen(false)
  const description = descriptionsForFormType[formType]

  return (
    <>
      <ConfirmDialog
        isOpen={isOpen}
        onCancel={closeDialog}
        title="Trade Portfolio Immediately?"
        customFooter={
          <Box display="grid" gap="md" width="100%">
            <Button onClick={handleTradeImmediately}>Trade Immediately</Button>
            <Button onClick={handleTradeOnCadence}>
              Trade on Existing Cadence
            </Button>
            <Button variant="secondary" active={false} onClick={closeDialog}>
              Back
            </Button>
          </Box>
        }
        description={
          <Box display="grid" gap="md">
            {description.map((text, i) => (
              <Text
                id={`text-` + i}
                fontSize={FONT_SIZE.fs16}
                fontWeight={400}
                color={COLOR.coolGray600}
              >
                {text}
              </Text>
            ))}
            <Text
              id="text-wash-sales"
              fontSize={FONT_SIZE.fs16}
              fontWeight={500}
              color={COLOR.coolGray600}
            >
              Trading immediately may trigger a wash sale, depending on prior
              trading activity. Wash sales will be listed on a brokerage
              statement and may be disclosed to the IRS. Please consult a tax
              adviser for guidance. Portfolios trade on a 32 day cadence,
              meaning trading on the existing cadence will help mitigate the
              risk of wash sales.
            </Text>
          </Box>
        }
        colorScheme="purple"
        style={{
          position: 'absolute',
        }}
      />
    </>
  )
}

export default TradePreferenceDialog
