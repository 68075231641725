import { Container } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@heroicons/react/outline'
import { analytics } from 'analytics'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import {
  ACCOUNT_STATUS,
  ASSET_LIST_TYPE,
  ASSET_SUPPORTED_TYPE,
  PortfolioLinkType,
} from 'types'

import { COLOR } from '../styles/constants/color'
import { paveApi } from 'api'
import {
  Box,
  Button,
  ConfirmDialog,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Text,
} from 'components/common'
import BreadcrumbBar from 'components/common/BreadcrumbBar/BreadcrumbBar'
import Card from 'components/common/Card/Card'
import FlexBox from 'components/common/FlexBox/FlexBox'
import {
  AssetList,
  EditHoldingsModal,
  EditPortfolioSlideIn,
  PageLayout,
  PerformanceChartRedirectCard,
  PlaidPerformanceAccessModal,
  PortfolioAllSuggestedTradesModal,
  PortfolioAllocation,
  PortfolioHeader,
  PortfolioPreferences,
  PortfolioStatistics,
  PortfolioSuggestedTrades,
} from 'components/partials'
import EditGeneralSettingsSlideIn from 'components/partials/EditGeneralSettingsSlideIn'
import ModalLoader from 'components/partials/ModalLoader'
import InitiateWithdrawalModal from 'components/partials/Modals/InitiateWithdrawalModal'
import UnsupportedAssetsPopOver from 'components/partials/PopOver/UnsupportedAssetsPopOver'
import CashCard from 'components/partials/Portfolio/CashCard'
import DeletePortfolioCard from 'components/partials/Portfolio/DeletePortfolioCard'
import InteractiveBrokersStatus from 'components/partials/Portfolio/InteractiveBrokersStatus'
import { ERROR_MESSAGE_1 } from 'constants/content.constants'
import { DEFAULT_TARGET_CASH_WEIGHT } from 'constants/portfolio.constants'
import {
  PORTFOLIO_EDIT_ASSETS_CLICKED,
  PORTFOLIO_EDIT_CLICKED,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import {
  openEditPortfolioAssets,
  openPortfolioAllSuggestedTrades,
} from 'store/modalsSlice'
import { useAppDispatch } from 'store/storeHooks'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import {
  getIbAccountState,
  getIbAccountStatus,
  getIbUsername,
  getIsAccountDisconnected,
  getPortfolioLinkType,
  getSchwabAccountStatus,
  isAutomationSupportedPortfolio,
  isManualPortfolio,
  shouldFreezeHoldings,
  shouldLockPortfolio,
} from 'utils/portfolioUtil'

function PortfolioPage() {
  const dispatch = useAppDispatch()
  const { externalId } = useParams()
  const navigate = useNavigate()
  const { showErrorToast, showSuccessToast } = useCustomToast()

  const {
    useGetPortfolioQuery,
    useGetPortfoliosStatsQuery,
    useGetIbSsoSessionMutation,
  } = paveApi
  const {
    data: portfolio,
    isFetching: isPortfolioFetching,
    isLoading: isPortfolioLoading,
    refetch: refetchPortfolio,
  } = useGetPortfolioQuery(externalId || '')
  const {
    data: portfolioStats,
    isLoading: isPortfolioStatsLoading,
    isFetching: isPortfolioStatsFetching,
    refetch: refetchPortfolioStats,
  } = useGetPortfoliosStatsQuery(portfolio?.id, {
    skip: !portfolio?.id,
  })
  const { data } = paveApi.useGetClientAccountQuery(
    portfolio?.clientAccountId || '',
    {
      skip: !portfolio?.clientAccountId,
    }
  )

  const [
    markCashWithdrawalCompleteAsComplete,
    { isLoading: isMarkWithdrawalLoading },
  ] = paveApi.useMarkCashWithdrawalCompleteAsCompleteMutation()
  const [deletePortfolio, { isLoading: isDeletePortfolioLoading }] =
    paveApi.useDeletePortfolioMutation()
  const [getIbSsoSession, { isLoading: isIbSsoSessionLoading }] =
    useGetIbSsoSessionMutation()
  const {
    data: portfolioAllocation,
    refetch: refetchPortfolioAllocation,
    isFetching: isPortfolioAllocationFetching,
  } = paveApi.useGetPortfolioBreakdownsQuery(portfolio?.id || '', {
    refetchOnMountOrArgChange: true,
    skip: !portfolio?.id,
  })

  const [rebalancePortfolio, { isLoading: isRebalanceLoading }] =
    paveApi.useRebalancePortfolioMutation()

  const isLoading = isDeletePortfolioLoading || isIbSsoSessionLoading

  const [isLocked, setIsLocked] = useState(false)
  const [showEditPortfolioModal, setShowEditPortfolioModal] = useState(false)
  const [showEditGeneralSettingsModal, setShowEditGeneralSettingsModal] =
    useState(false)
  const [showPlaidPerformanceAccessModal, setShowPlaidPerformanceAccessModal] =
    useState(false)
  const [currentCashAllocation, setCurrentCashAllocation] = useState(0)

  const showEditPortfolioSlideIn = () => {
    setShowEditPortfolioModal(true)
    analytics.track(PORTFOLIO_EDIT_CLICKED)
  }
  const hideEditPortfolioSlideIn = () => setShowEditPortfolioModal(false)

  const showEditGeneralSettingsSlideIn = () =>
    setShowEditGeneralSettingsModal(true)
  const hideEditGeneralSettingsSlideIn = () =>
    setShowEditGeneralSettingsModal(false)

  const linkType = getPortfolioLinkType(portfolio)
  const isAutomationSupported = isAutomationSupportedPortfolio(portfolio)
  const portfolioSchwabStatus = getSchwabAccountStatus(portfolio)
  const portfolioIbStatus = getIbAccountStatus(portfolio)
  const portfolioIbState = getIbAccountState(portfolio)
  const [freezeHoldings, setFreezeHoldings] = useState(false)
  const [isRebalancePortfolioModalOpen, setIsRebalancePortfolioModalOpen] =
    useState<boolean>(false)
  const [isInitiateWithdrawalModalOpen, setIsInitiateWithdrawalModalOpen] =
    useState<boolean>(false)
  const portfolioValue = portfolio?.value || 0
  const isPortfolioDisconnected = getIsAccountDisconnected(
    portfolio?.accountStatus
  )
  const showDeletePortfolioCard =
    portfolioValue === 0 || isPortfolioDisconnected

  const [searchParams] = useSearchParams()
  const entryPoint = searchParams.get('entryPoint')

  const isThirdPartyManaged = portfolio?.isThirdPartyManaged ?? false
  const isLinkedPortfolio = linkType !== PortfolioLinkType.None
  const showEditHoldingsButton = !isThirdPartyManaged && !isLinkedPortfolio
  useEffect(() => {
    if (entryPoint === 'home') {
      dispatch(openPortfolioAllSuggestedTrades())
    }
  }, [dispatch, entryPoint])

  useEffect(() => {
    if (portfolio) {
      if (shouldLockPortfolio(portfolio)) {
        setIsLocked(true)
      }
      if (shouldFreezeHoldings(portfolio)) {
        setFreezeHoldings(true)
      }
      refetchPortfolioStats()
      refetchPortfolioAllocation()
    }
  }, [portfolio, refetchPortfolioStats, refetchPortfolioAllocation])

  useEffect(() => {
    if (portfolio) {
      const availableCash = portfolio.availableCash ?? 0
      const availableCashWeight =
        portfolioValue === 0 ? 0 : availableCash / portfolioValue
      setCurrentCashAllocation(availableCashWeight)
    }
  }, [portfolio, setCurrentCashAllocation, portfolioValue])

  const onRedirectToIb = () => {
    const username = getIbUsername(portfolio)
    if (username) {
      getIbSsoSession({ username: username })
        .unwrap()
        .then(({ ssoUrl }) => {
          window.open(ssoUrl, '_blank', 'noreferrer')
        })
        .catch(() => showErrorToast(ERROR_MESSAGE_1))
    } else {
      showErrorToast(ERROR_MESSAGE_1)
    }
  }

  function onDeletePortfolio() {
    deletePortfolio(portfolio?.id || '')
      .unwrap()
      .then(() => {
        showSuccessToast('Portfolio deleted!')
        navigate(`/account/${portfolio?.clientAccountId}`)
      })
      .catch(() => {
        showErrorToast('An error occurred deleting your portfolio')
      })
  }

  const handleEditAssets = () => {
    dispatch(openEditPortfolioAssets(portfolio))
    analytics.track(PORTFOLIO_EDIT_ASSETS_CLICKED)
  }

  async function onRebalancePortfolio() {
    const errorMessage = 'An error occurred investing your excess cash'
    if (!portfolio) {
      showErrorToast(errorMessage)
      return
    }

    rebalancePortfolio(portfolio.id)
      .unwrap()
      .then(() => {
        refetchPortfolio()
        showSuccessToast(
          'Suggested trades generated',
          'A new set of suggested trades was successfully generated for your portfolio'
        )
      })
      .catch(() => {
        showErrorToast(errorMessage)
      })
      .finally(() => {
        setIsRebalancePortfolioModalOpen(false)
      })
  }

  const onCancelonRebalancePortfolio = () => {
    setIsRebalancePortfolioModalOpen(false)
  }

  const handleWithdrawalComplete = () => {
    if (portfolio?.id) {
      markCashWithdrawalCompleteAsComplete({ id: portfolio.id })
        .unwrap()
        .then(() => {
          showSuccessToast('Successfully marked withdrawal as completed!')
        })
        .catch(() => {
          showErrorToast(
            'An error occurred when marking the withdrawal as complete.'
          )
        })
    }
  }

  const hasMoneyMarket = !!portfolio?.assets.some(
    (asset) => asset.supportedType === ASSET_SUPPORTED_TYPE.MONEY_MARKET
  )

  const breadcrumbs = [
    {
      name: 'Home Page',
      route: `/`,
    },
    {
      name: data?.clientAccount?.displayName || 'Group Overview',
      route: `/account/${portfolio?.clientAccountId || ''}`,
    },
    {
      name: portfolio?.displayName || 'Portfolio Overview',
      route: `/portfolio/${portfolio?.id || ''}`,
    },
  ]

  if (
    linkType === PortfolioLinkType.InteractiveBrokers &&
    !(portfolioIbStatus === 'O')
  ) {
    return (
      <PageLayout showLoader={isPortfolioLoading}>
        {portfolio?.id && <BreadcrumbBar paths={breadcrumbs} />}
        <Box paddingTop={SPACE.sp32}>
          <PortfolioHeader portfolio={portfolio} />
          <InteractiveBrokersStatus
            isLoading={isLoading}
            onDeletePortfolio={onDeletePortfolio}
            onRedirectToIb={onRedirectToIb}
            portfolioIbStatus={portfolioIbStatus}
            portfolioIbState={portfolioIbState}
          />
        </Box>
      </PageLayout>
    )
  }

  if (
    linkType === PortfolioLinkType.CharlesSchwab &&
    portfolioSchwabStatus === ACCOUNT_STATUS.CLOSED
  ) {
    return (
      <PageLayout showLoader={isPortfolioLoading}>
        {portfolio?.id && <BreadcrumbBar paths={breadcrumbs} />}
        <Box paddingTop={SPACE.sp32}>
          <FlexBox
            flexDirection="column"
            alignItems="flex-start"
            padding={SPACE.sp24}
            gap={SPACE.sp24}
            background={COLOR.white}
            border={`1px solid ${COLOR.gray200}`}
            borderRadius={SPACE.sp8}
          >
            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs20}
              fontWeight="bold"
            >
              Account Closed
            </Text>
            <Text fontSize={FONT_SIZE.fs14} color={COLOR.coolGray700} as="p">
              Your Schwab RIA account was closed. Feel free to delete this
              portfolio.
            </Text>
            <Button
              disabled={isLoading}
              onClick={onDeletePortfolio}
              size="lg"
              type="submit"
              variant="primary"
            >
              Delete Portfolio
            </Button>
          </FlexBox>
        </Box>
      </PageLayout>
    )
  }

  return (
    <>
      <PageLayout showLoader={isPortfolioLoading}>
        {portfolio?.id && <BreadcrumbBar paths={breadcrumbs} />}
        <Box margin={SPACE.sp32} marginTop={SPACE.sp24} maxWidth="75rem">
          <Box marginBottom={SPACE.sp24}>
            <PortfolioHeader portfolio={portfolio} />
          </Box>
          <FlexBox height="35rem" gap={SPACE.sp24}>
            <FlexBox
              flexDirection="column"
              height="100%"
              gap={SPACE.sp24}
              flex={1}
            >
              {portfolio && !isManualPortfolio(portfolio) && (
                <PerformanceChartRedirectCard
                  portfolio={portfolio}
                  openPlaidModal={() =>
                    setShowPlaidPerformanceAccessModal(true)
                  }
                />
              )}
              <Box height="100%">
                <Tabs>
                  {showDeletePortfolioCard ? (
                    <DeletePortfolioCard
                      onDeletePortfolio={onDeletePortfolio}
                      isLoading={isDeletePortfolioLoading}
                      linkType={linkType}
                      accountStatus={portfolio?.accountStatus}
                    />
                  ) : (
                    <>
                      <TabList>
                        <Tab>Suggested Trades</Tab>
                        <Tab disabled={isPortfolioStatsLoading}>Statistics</Tab>
                      </TabList>

                      <TabPanel>
                        <PortfolioSuggestedTrades
                          statistics={portfolioStats}
                          isStatisticsLoading={
                            isPortfolioStatsLoading || isPortfolioStatsFetching
                          }
                          isPortfolioFetching={isPortfolioFetching}
                          portfolio={portfolio}
                          linkType={linkType}
                          isLocked={isLocked}
                          isAutomationSupported={isAutomationSupported}
                          isPortfolioLoading={isPortfolioLoading}
                          editPortfolio={showEditPortfolioSlideIn}
                          showEditPortfolioSlideIn={showEditPortfolioSlideIn}
                          refetchPortfolio={refetchPortfolio}
                        />
                      </TabPanel>
                      <TabPanel>
                        <PortfolioStatistics
                          statistics={portfolioStats}
                          isLoading={isPortfolioStatsLoading}
                          benchmark={portfolio?.benchmark}
                        />
                      </TabPanel>
                    </>
                  )}
                </Tabs>
              </Box>
            </FlexBox>
            <Card height="100%" width="24.625rem" overflowY="scroll">
              <PortfolioAllocation
                data={portfolioAllocation?.breakdowns}
                isLoading={isPortfolioAllocationFetching}
                value={portfolioValue}
              />
            </Card>
          </FlexBox>
          <FlexBox gap={SPACE.sp24} marginTop={SPACE.sp24}>
            <Box width="17rem">
              <CashCard
                availableCash={portfolio?.availableCash}
                withdrawalCashRequest={
                  portfolio?.investmentPreference?.withdrawalCashRequest
                }
                handleRebalanceCash={() =>
                  setIsRebalancePortfolioModalOpen(true)
                }
                handleInitiateWithdrawal={() =>
                  setIsInitiateWithdrawalModalOpen(true)
                }
                handleWithdrawalComplete={handleWithdrawalComplete}
                showWithdrawalButton={
                  portfolioValue > 0 &&
                  !!portfolio?.isInvestmentPreferenceCompleted
                }
                targetCashWeight={
                  portfolio?.investmentPreference?.targetCashWeight ||
                  DEFAULT_TARGET_CASH_WEIGHT
                }
                currentCashAllocation={currentCashAllocation}
                recommendedAssets={portfolio?.recommendedAssets || []}
                portfolioIsLocked={isLocked}
                isLoading={isRebalanceLoading}
                hasMoneyMarket={hasMoneyMarket}
                isInvestmentPreferenceCompleted={
                  portfolio?.isInvestmentPreferenceCompleted
                }
              />

              <PortfolioPreferences
                portfolio={portfolio}
                linkType={linkType}
                openEditGeneralSettings={showEditGeneralSettingsSlideIn}
                openEditPortfolio={showEditPortfolioSlideIn}
                disabled={
                  isLocked || isPortfolioStatsLoading || isPortfolioFetching
                }
              />

              {linkType === PortfolioLinkType.InteractiveBrokers && (
                <Container
                  marginTop={SPACE.sp24}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  padding={SPACE.sp24}
                  marginBottom={SPACE.sp20}
                  backgroundColor={COLOR.white}
                  borderRadius="8px"
                  gap={SPACE.sp20}
                  width="100%"
                  border="1px solid"
                  borderColor={COLOR.coolGray200}
                >
                  <Text
                    fontSize={FONT_SIZE.fs16}
                    fontWeight="bold"
                    lineHeight="md"
                    color={COLOR.coolGray700}
                  >
                    Reporting
                  </Text>
                  <Text fontSize={FONT_SIZE.fs14} color={COLOR.coolGray700}>
                    Use the button below to access your monthly statements,
                    trade confirms, and tax documents through the Interactive
                    Brokers portal.
                  </Text>
                  <Box
                    onClick={onRedirectToIb}
                    style={{ cursor: 'pointer' }}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap={SPACE.sp8}
                  >
                    <Text
                      fontSize={FONT_SIZE.fs14}
                      color={COLOR.purple700}
                      fontWeight="bold"
                      marginRight={0}
                    >
                      See Reports
                    </Text>
                    <ExternalLinkIcon
                      color={COLOR.purple700}
                      height={SPACE.sp18}
                      width={SPACE.sp18}
                    />
                  </Box>
                </Container>
              )}
            </Box>
            <FlexBox
              flexDirection="column"
              flex={1}
              marginBottom={SPACE.sp24}
              gap={SPACE.sp24}
            >
              {portfolio && !isEmpty(portfolio?.unsupportedAssets) && (
                <AssetList
                  assetType={ASSET_LIST_TYPE.UNSUPPORTED}
                  initialAssets={portfolio.unsupportedAssets}
                  portfolioId={portfolio.id}
                  rightContent={<UnsupportedAssetsPopOver />}
                />
              )}
              {portfolio && (
                <AssetList
                  assetType={ASSET_LIST_TYPE.SUPPORTED}
                  initialAssets={portfolio.assets.filter(
                    (assets) =>
                      assets.supportedType === ASSET_SUPPORTED_TYPE.ALL
                  )}
                  portfolioId={portfolio.id}
                  rightContent={
                    showEditHoldingsButton ? (
                      <Button
                        variant="tertiary"
                        size="xs"
                        onClick={handleEditAssets}
                        disabled={isLocked || freezeHoldings}
                      >
                        Edit Holdings
                      </Button>
                    ) : null
                  }
                />
              )}
              {portfolio && hasMoneyMarket && (
                <AssetList
                  assetType={ASSET_LIST_TYPE.MONEY_MARKET}
                  initialAssets={portfolio.assets.filter(
                    (assets) =>
                      assets.supportedType === ASSET_SUPPORTED_TYPE.MONEY_MARKET
                  )}
                  portfolioId={portfolio.id}
                />
              )}
            </FlexBox>
          </FlexBox>
        </Box>
        <PlaidPerformanceAccessModal
          isOpen={showPlaidPerformanceAccessModal}
          onClose={() => setShowPlaidPerformanceAccessModal(false)}
        />
        {portfolio && (
          <PortfolioAllSuggestedTradesModal
            portfolio={portfolio}
            statistics={portfolioStats}
            isStatisticsLoading={isPortfolioStatsLoading}
          />
        )}
        {portfolio && (
          <EditPortfolioSlideIn
            closeModal={hideEditPortfolioSlideIn}
            portfolio={portfolio}
            showModal={showEditPortfolioModal}
          />
        )}
        {portfolio && (
          <EditGeneralSettingsSlideIn
            closeModal={hideEditGeneralSettingsSlideIn}
            portfolio={portfolio}
            showModal={showEditGeneralSettingsModal}
          />
        )}
        <EditHoldingsModal />
        {portfolio && (
          <InitiateWithdrawalModal
            isOpen={isInitiateWithdrawalModalOpen}
            onClose={() => setIsInitiateWithdrawalModalOpen(false)}
            portfolio={portfolio}
          />
        )}
        <ConfirmDialog
          isOpen={isRebalancePortfolioModalOpen}
          onCancel={onCancelonRebalancePortfolio}
          onConfirm={onRebalancePortfolio}
          confirmButtonText="Confirm"
          title="Confirm investment of excess cash?"
          disableConfirmButton={isRebalanceLoading}
          disableCancelButton={isRebalanceLoading}
          description={
            <FlexBox flexDirection="column" gap={SPACE.sp8}>
              <Text fontWeight={400} color={COLOR.coolGray600}>
                To invest this portfolio's excess cash, Pave Pro will generate a
                new set of suggested trades
              </Text>
              <Text fontWeight={400} color={COLOR.coolGray600}>
                If you have automation turned on, the trades will be placed
                automatically without requiring your approval.
              </Text>
              <Text fontWeight={400} color={COLOR.coolGray600}>
                If you have automation turned off, the suggested trades will not
                be placed until you manually approve them.
              </Text>
              <Text fontWeight={500} color={COLOR.coolGray600}>
                This action may trigger a wash sale as your last set of trades
                may have been placed within the last 30 days. Wash sales relate
                to realizing losses and trading the same security within a 30
                day window. Wash sales will be listed on a brokerage statement
                and may be disclosed to the IRS. Please contact a tax adviser
                for guidance.
              </Text>
            </FlexBox>
          }
          colorScheme="purple"
          style={{
            position: 'absolute',
            top: '20%',
          }}
        />
      </PageLayout>
      {isMarkWithdrawalLoading && <ModalLoader />}
    </>
  )
}

export default PortfolioPage
