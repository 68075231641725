import { analytics } from 'analytics'
import { isBoolean, isNumber, size } from 'lodash'
import { useMemo, useReducer, useState } from 'react'
import { NumericFormat } from 'react-number-format'

import { AssetClass, BenchmarkType } from 'enums'
import { InvestmentTemplate } from 'types'
import { InvestmentTemplateFormPayload } from 'types/InvestmentTemplateTypes'
import { ErrorObject } from 'types/generalTypes'

import { paveApi } from 'api'
import { Box, Dropdown, Text, TextInput } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { AutomationPopOver, DropdownWithTags } from 'components/partials'
import ExcludedAssetsDropdown from 'components/partials/CreatePortfolio/ExcludedAssetsDropdown'
import ModalLoader from 'components/partials/ModalLoader'
import TemplateAutomationConfirmDialog from 'components/partials/Settings/TemplateAutomationConfirmDialog'
import {
  clearFactorTilts,
  evaluateImpactedTaxOptimizationFields,
  investmentTemplateFormReducer,
  setAssetClass,
  setBenchmarkId,
  setBuyListId,
  setDisplayName,
  setEnableTaxOptimization,
  setExcludedAssetClasses,
  setExcludedAssets,
  setExcludedIndustries,
  setExcludedSectors,
  setFractionalShares,
  setStrategy,
  setTargetAssetCount,
  setTargetCashWeightPercent,
} from 'components/reducers/InvestmentTemplateFormReducer'
import {
  setAutomation,
  setPerformancePriority,
} from 'components/reducers/portfolioFormReducer'
import { DISPLAY_NAME_LENGTH_LIMIT } from 'constants/general.constants'
import { investmentTemplateFormOptions } from 'constants/investmentTemplate.constants'
import {
  DEFAULT_PERFORMANCE_PRIORITY,
  DEFAULT_PERFORMANCE_PRIORITY_WITH_TAX_OPTIMIZATION_OFF,
  DEFAULT_TARGET_CASH_WEIGHT_PLACEHOLDER,
  MINIMUM_VALID_TARGET_CASH_WEIGHT_PERCENT,
} from 'constants/portfolio.constants'
import {
  INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED_FAILED,
  INVESTMENT_TEMPLATE_EDIT_FORM_SUBMITTED_FAILED,
} from 'constants/track.constants'
import {
  INVALID_PERFORMANCE_PRIORITY,
  INVALID_TARGET_CASH_WEIGHT,
  NUMBER_GREATER_THAN_ZERO,
  REQUIRED_FIELD,
} from 'constants/validation.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import {
  formatToInvestmentTemplateForm,
  formatToInvestmentTemplatePayload,
} from 'utils/investmentTemplateFormUtil'

import { DropdownOptionString } from '../DropdownWithTags'
import InvestmentTemplateLearnMoreModal from '../Modals/InvestmentTemplateLearnMoreModal'
import BuyListPopOver from '../Portfolio/PortfolioPreferences/BuyListPopOver'
import TargetCashAllocationPopOver from '../Portfolio/PortfolioPreferences/TargetCashAllocationPopOver'
import { FactorTiltsFormSection } from './FactorTiltsFormSection'
import MultiAssetClassStrategySelector from './MultiAssetClassStrategySelector'

type Props = {
  initialTemplate: InvestmentTemplate
  showLoader?: boolean
  isNewTemplate: boolean
  submitInvestmentTemplateForm: (
    investmentTemplatePayload: InvestmentTemplateFormPayload
  ) => void
}

const {
  assetClassOptions,
  fractionalTradingOptions,
  enableTaxOptimizationOptions,
  automationOptions,
} = investmentTemplateFormOptions
export default function InvestmentTemplateForm({
  initialTemplate,
  showLoader,
  isNewTemplate,
  submitInvestmentTemplateForm,
}: Props) {
  const initialTemplateFormData =
    formatToInvestmentTemplateForm(initialTemplate)
  const [formState, dispatch] = useReducer(
    investmentTemplateFormReducer,
    initialTemplateFormData
  )
  const [isAutomationConfirmDialogOpen, setIsAutomationConfirmDialogOpen] =
    useState<boolean>(false)
  const [selectedAutomationValue, setSelectedNewAutomationValue] =
    useState<boolean>(false)

  const { showErrorToast } = useCustomToast()

  const { data: benchmarks } = paveApi.useGetBenchmarksQuery()
  const { data: buyLists } = paveApi.useGetBuyListNamesQuery()
  const { data: sectors } = paveApi.useGetSectorsQuery()
  const { data: assets } = paveApi.useGetAssetsQuery()

  const [formErrors, setFormErrors] = useState<ErrorObject>({})
  const isMultiAssetClass =
    formState.assetClass === AssetClass.MULTI_ASSET_CLASS

  const [isLearnMoreModalOpen, setIsLearnMoreModalOpen] =
    useState<boolean>(false)

  const benchmarkOptions: DropdownOptionString[] = useMemo(() => {
    return (
      benchmarks
        ?.filter((benchmark) => benchmark.type === BenchmarkType.STOCK)
        .map(({ name, id }) => ({
          label: name,
          value: `${id}`,
        })) || []
    )
  }, [benchmarks])

  const buyListOptions = (buyLists ?? []).map(({ displayName, id }) => {
    return { label: displayName, value: id }
  }) as DropdownOptionString[]

  const sectorOptions: DropdownOptionString[] = []
  const industryOptions: DropdownOptionString[] = []
  const assetsOptions: DropdownOptionString[] = []
  const assetClassesOptions: DropdownOptionString[] = []
  const assetClassSet = new Set()

  sectors?.forEach((sector) => {
    sectorOptions.push({
      label: sector.name,
      value: sector.name,
    })
    sector.industries.forEach((industry) => {
      industryOptions.push({
        label: industry.name,
        value: industry.name,
      })
    })
  })

  assets?.forEach((a) => {
    assetsOptions.push({ label: a.name, value: a.symbol })

    if (a.class) {
      assetClassSet.add(a.class)
    }
  })

  Array.from(assetClassSet).forEach((a) => {
    assetClassesOptions.push({ label: String(a), value: String(a) })
  })

  // TODO ADV-29: Investigate and refactor the validator object and function
  const validateData = () => {
    const newErrors: ErrorObject = {}
    const {
      assetClass,
      benchmarkId,
      factorTilts,
      displayName,
      enableTaxOptimization,
      performancePriority,
      multiAssetClassStrategy,
      targetAssetCount,
      targetCashWeightPercent,
    } = formState

    if (!displayName) {
      newErrors.displayName = REQUIRED_FIELD
    }

    if (targetAssetCount === 0) {
      newErrors.targetAssetCount = NUMBER_GREATER_THAN_ZERO
    }

    if (
      isNumber(targetCashWeightPercent) &&
      (targetCashWeightPercent < MINIMUM_VALID_TARGET_CASH_WEIGHT_PERCENT ||
        targetCashWeightPercent > 100)
    ) {
      newErrors.targetCashWeightPercent = INVALID_TARGET_CASH_WEIGHT
    }

    const hasFactorTilts = factorTilts && factorTilts.length > 0
    const hasEnableTaxOptimization = isBoolean(enableTaxOptimization)

    if (!assetClass) {
      newErrors.assetClass = REQUIRED_FIELD
    }

    // benchmark
    if (assetClass === AssetClass.EQUITY) {
      if (!benchmarkId) {
        newErrors.benchmarkId = REQUIRED_FIELD
      }
    } else if (!multiAssetClassStrategy) {
      newErrors.strategy = REQUIRED_FIELD
    }

    if (hasFactorTilts && !performancePriority) {
      newErrors.performancePriority = REQUIRED_FIELD
    }

    if (performancePriority) {
      if (hasFactorTilts) {
        if (
          performancePriority !== DEFAULT_PERFORMANCE_PRIORITY &&
          performancePriority !==
            DEFAULT_PERFORMANCE_PRIORITY_WITH_TAX_OPTIMIZATION_OFF
        ) {
          newErrors.performancePriority = INVALID_PERFORMANCE_PRIORITY
        }

        if (hasEnableTaxOptimization) {
          const defaultPerformacePriority = enableTaxOptimization
            ? DEFAULT_PERFORMANCE_PRIORITY
            : DEFAULT_PERFORMANCE_PRIORITY_WITH_TAX_OPTIMIZATION_OFF
          if (performancePriority !== defaultPerformacePriority)
            newErrors.performancePriority = INVALID_PERFORMANCE_PRIORITY
        }
      }
    }

    setFormErrors(newErrors)
    return {
      isValidData: size(newErrors) === 0,
      errors: newErrors,
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const { isValidData, errors } = validateData()

    if (!isValidData) {
      showErrorToast('Please complete the form to continue')
      analytics.track(
        isNewTemplate
          ? INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED_FAILED
          : INVESTMENT_TEMPLATE_EDIT_FORM_SUBMITTED_FAILED,
        {
          errors: errors,
        }
      )
      return
    }

    const investmentTemplatePayload =
      formatToInvestmentTemplatePayload(formState)
    submitInvestmentTemplateForm(investmentTemplatePayload)
  }

  return (
    <>
      {showLoader && <ModalLoader />}
      <form id="investment-template-form" onSubmit={handleSubmit}>
        <Box marginBottom={SPACE.sp24}>
          <Box marginBottom={SPACE.sp8}>
            <Text
              fontWeight="medium"
              fontSize={FONT_SIZE.fs14}
              lineHeight="xs"
              color={COLOR.coolGray700}
            >
              Please fill out the fields you would like to set for all the
              portfolios this template is applied to. Any blank fields will
              remain editable at the portfolio level.{' '}
              <Text
                color={COLOR.purple900}
                fontWeight="bold"
                style={{ cursor: 'pointer' }}
                onClick={() => setIsLearnMoreModalOpen(true)}
              >
                Learn More
              </Text>
            </Text>
          </Box>
          <Box marginBottom={SPACE.sp20}>
            <Text
              fontWeight="medium"
              fontSize={FONT_SIZE.fs14}
              lineHeight="xs"
              color={COLOR.coolGray700}
            >
              <Text color={COLOR.purple900} fontWeight="bold">
                *
              </Text>{' '}
              = required to create a template
            </Text>
            <FlexBox alignItems="center" gap={SPACE.sp4}>
              <Box
                border={`1px solid ${COLOR.green700}`}
                borderRadius={SPACE.sp4}
                width={SPACE.sp12}
                height={SPACE.sp12}
              />
              <Text
                fontWeight="medium"
                fontSize={FONT_SIZE.fs14}
                lineHeight="xs"
                color={COLOR.coolGray700}
              >
                {' '}
                = required to complete preferences
              </Text>
            </FlexBox>
          </Box>
          <Box marginBottom={SPACE.sp20}>
            <Box marginBottom={SPACE.sp8}>
              <Text
                color={COLOR.coolGray600}
                fontSize={FONT_SIZE.fs16}
                fontWeight={700}
              >
                General
              </Text>
            </Box>
            <TextInput
              id="template-name"
              errorMessage={formErrors.displayName}
              label="Template Name"
              maxLength={DISPLAY_NAME_LENGTH_LIMIT}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(setDisplayName(e.target.value.trim()))
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(setDisplayName(e.target.value))
              }
              placeholder="Template Name"
              showRequiredStar={true}
              value={formState.displayName}
            />
          </Box>
          <Box marginBottom={SPACE.sp8}>
            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs16}
              fontWeight={700}
            >
              Trading
            </Text>
          </Box>
          <FlexBox gap={SPACE.sp12}>
            <Box flex={1}>
              <Dropdown
                id="automation"
                label="Automation"
                popOver={<AutomationPopOver />}
                isSearchable={false}
                onChange={({ value }: any) => {
                  if (value !== formState.automation) {
                    setSelectedNewAutomationValue(value)
                    setIsAutomationConfirmDialogOpen(true)
                  }
                }}
                options={automationOptions}
                placeholder="Select"
                value={formState.automation}
                variant="green"
                isClearable={true}
                onClearValue={() => dispatch(setAutomation(null))}
              />
            </Box>
            <Box flex={1}>
              <Dropdown
                id="fractional-shares"
                isSearchable={false}
                label="Fractional Shares"
                onChange={({ value }: any) => {
                  dispatch(setFractionalShares(value))
                }}
                options={fractionalTradingOptions}
                placeholder="Select"
                value={formState.fractionalShares}
                isClearable={true}
                onClearValue={() => dispatch(setFractionalShares(null))}
                variant="green"
              />
            </Box>
            <Box flex={1}>
              <Dropdown
                id="tax-optimization"
                isClearable={true}
                isSearchable={false}
                label="Tax Optimization"
                onChange={(option: any) => {
                  dispatch(setEnableTaxOptimization(option.value))
                  dispatch(evaluateImpactedTaxOptimizationFields())
                }}
                onClearValue={() => {
                  dispatch(setEnableTaxOptimization(null))
                  dispatch(clearFactorTilts())
                  dispatch(setPerformancePriority(null))
                }}
                options={enableTaxOptimizationOptions}
                placeholder="Select"
                value={formState.enableTaxOptimization}
                variant="green"
              />
            </Box>
          </FlexBox>
        </Box>

        <Box marginBottom={SPACE.sp24}>
          <Box marginBottom={SPACE.sp8}>
            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs16}
              fontWeight={700}
            >
              Strategy
            </Text>
          </Box>
          <FlexBox gap={SPACE.sp12}>
            <Box flex={1}>
              <Dropdown
                id="asset-class"
                label="Asset Class"
                value={assetClassOptions.find(
                  ({ value }) => value === formState.assetClass
                )}
                isDisabled={!isNewTemplate}
                isSearchable={false}
                options={assetClassOptions}
                onChange={(option: unknown) => {
                  const { value } = option as { value: AssetClass }
                  dispatch(setAssetClass(value))
                  dispatch(setBenchmarkId(null))
                  dispatch(setStrategy(null))
                }}
                showRequiredStar={true}
                variant="green"
              />
            </Box>
            {isMultiAssetClass ? (
              <Box flex={1}>
                <MultiAssetClassStrategySelector
                  dispatch={dispatch}
                  formErrors={formErrors}
                  formState={formState}
                  onClearValue={() => {
                    dispatch(setBenchmarkId(null))
                    dispatch(setStrategy(null))
                  }}
                  variant="green"
                />
              </Box>
            ) : (
              <Box flex={1}>
                <Dropdown
                  id="benchmark"
                  errorMessage={formErrors?.benchmarkId}
                  isSearchable={false}
                  label="Benchmark"
                  onChange={(option: any) =>
                    dispatch(setBenchmarkId(option.value))
                  }
                  options={benchmarkOptions}
                  onClearValue={() => dispatch(setBenchmarkId(null))}
                  showRequiredStar={true}
                  value={formState.benchmarkId}
                  variant="green"
                />
              </Box>
            )}
          </FlexBox>
        </Box>

        <Box marginBottom={SPACE.sp24}>
          <Box marginBottom={SPACE.sp8}>
            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs16}
              fontWeight={700}
            >
              Constraints
            </Text>
          </Box>

          {isMultiAssetClass ? (
            <FlexBox gap={SPACE.sp12}>
              <Box flex={1}>
                <DropdownWithTags
                  id="excluded-asset-classes"
                  options={assetClassesOptions}
                  tagColor="red"
                  label="Excluded Asset Classes"
                  placeholder="Search by asset class"
                  onChange={(value) =>
                    dispatch(setExcludedAssetClasses(value.sort()))
                  }
                  value={formState.excludedAssetClasses}
                />
              </Box>
              <Box flex={1}>
                <ExcludedAssetsDropdown
                  assetsOptions={assetsOptions}
                  onChange={(value) =>
                    dispatch(setExcludedAssets(value.sort()))
                  }
                  excludedAssets={formState.excludedAssets}
                />
              </Box>
            </FlexBox>
          ) : (
            <>
              <FlexBox gap={SPACE.sp12}>
                <Box flex={1}>
                  <DropdownWithTags
                    id="excluded-sectors"
                    options={sectorOptions}
                    tagColor="red"
                    label="Excluded Sectors"
                    placeholder="Search by sector"
                    onChange={(value) =>
                      dispatch(setExcludedSectors(value.sort()))
                    }
                    value={formState.excludedSectors}
                  />
                </Box>
                <Box flex={1}>
                  <DropdownWithTags
                    id="excluded-industries"
                    options={industryOptions}
                    tagColor="red"
                    label="Excluded Industries"
                    placeholder="Search by industry"
                    onChange={(value) =>
                      dispatch(setExcludedIndustries(value.sort()))
                    }
                    value={formState.excludedIndustries}
                  />
                </Box>
              </FlexBox>
              <Box flex={1} marginTop={SPACE.sp12}>
                <ExcludedAssetsDropdown
                  assetsOptions={assetsOptions}
                  onChange={(value) =>
                    dispatch(setExcludedAssets(value.sort()))
                  }
                  excludedAssets={formState.excludedAssets}
                />
              </Box>
            </>
          )}
        </Box>

        <Box marginBottom={SPACE.sp24}>
          <FactorTiltsFormSection
            dispatch={dispatch}
            formErrors={formErrors}
            formState={formState}
            isClearable={true}
          />
        </Box>

        <Box marginBottom={SPACE.sp24}>
          <Box marginBottom={SPACE.sp8}>
            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs16}
              fontWeight={700}
            >
              Assets
            </Text>
          </Box>
          <FlexBox gap={SPACE.sp12}>
            <Box flex={1}>
              <FlexBox
                flexDirection="row"
                gap={SPACE.sp10}
                paddingBottom={SPACE.sp6}
              >
                <Text
                  color={COLOR.coolGray600}
                  fontSize={FONT_SIZE.fs14}
                  fontWeight={500}
                  marginBottom={SPACE.sp4}
                >
                  Target Asset Count
                </Text>
              </FlexBox>
              <TextInput
                id="target-asset-count"
                errorMessage={formErrors.targetAssetCount}
                min={0}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(setTargetAssetCount(parseInt(e.target.value)))
                }
                placeholder="Enter a number"
                step={1}
                type="number"
                value={formState.targetAssetCount ?? ''}
                variant="green"
              />
            </Box>
            <Box flex={1}>
              <FlexBox
                flexDirection="row"
                gap={SPACE.sp10}
                paddingBottom={SPACE.sp6}
              >
                <Text
                  color={COLOR.coolGray600}
                  fontSize={FONT_SIZE.fs14}
                  fontWeight={500}
                  marginBottom={SPACE.sp4}
                >
                  Target Cash Allocation
                </Text>
                <TargetCashAllocationPopOver />
              </FlexBox>
              <NumericFormat
                id="target-cash-allocation"
                errorMessage={formErrors.targetCashWeightPercent}
                allowNegative={false}
                allowLeadingZeros={false}
                decimalScale={2}
                maxLength={6}
                suffix="%"
                customInput={TextInput}
                placeholder={String(DEFAULT_TARGET_CASH_WEIGHT_PLACEHOLDER)}
                step={1}
                value={formState.targetCashWeightPercent ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch(
                    setTargetCashWeightPercent(parseFloat(e.target.value))
                  )
                }
              />
            </Box>
            {buyListOptions?.length > 0 && (
              <Box flex={1}>
                <Dropdown
                  id="buy-list"
                  isClearable={true}
                  isSearchable={false}
                  label="Buy List"
                  onClearValue={() => {
                    dispatch(setBuyListId(null))
                  }}
                  onChange={(option: any) => {
                    dispatch(setBuyListId(option.value))
                  }}
                  popOver={<BuyListPopOver />}
                  options={buyListOptions}
                  placeholder="Select"
                  value={formState.buyListId}
                />
              </Box>
            )}
          </FlexBox>
        </Box>
      </form>
      <InvestmentTemplateLearnMoreModal
        isOpen={isLearnMoreModalOpen}
        onClose={() => setIsLearnMoreModalOpen(false)}
      />
      <TemplateAutomationConfirmDialog
        isAutomationConfirmDialogOpen={isAutomationConfirmDialogOpen}
        setIsAutomationConfirmDialogOpen={setIsAutomationConfirmDialogOpen}
        onConfirm={() => {
          dispatch(setAutomation(selectedAutomationValue))
        }}
        automation={selectedAutomationValue}
      />
    </>
  )
}
