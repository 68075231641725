import { orderBy } from 'lodash'
import React, { useEffect, useState } from 'react'

import { PortfolioSummary } from 'types'
import { StatusFilterOptions } from 'types/InvestmentTemplateTypes'

import { Box, Button, Dropdown, SearchBar, Text } from 'components/common'
import Card from 'components/common/Card/Card'
import { ChecklistOption } from 'components/common/CheckList/CheckList'
import FlexBox from 'components/common/FlexBox/FlexBox'
import Pagination from 'components/common/Pagination/Pagination'
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from 'constants/pagination.constants'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { jsonClone } from 'utils/generalUtils'
import { getIsAccountDisconnected } from 'utils/portfolioUtil'
import { searchFilter } from 'utils/searchFilter'

import { DropdownOptionString } from '../DropdownWithTags'
import ListView from '../ListView'
import InvestmentTemplatesPortfolioListItem from './InvestmentTemplatesPortfolioListItem'

type Props = {
  portfolios: PortfolioSummary[]
  handleApplyTemplate: () => void
  handleRemoveTemplate: () => void
  selectedPortfolios: ChecklistOption[]
  setSelectedPortfolios: React.Dispatch<React.SetStateAction<ChecklistOption[]>>
  statusOptions: DropdownOptionString<StatusFilterOptions>[]
  groupOptions: DropdownOptionString[]
  templateOptions: DropdownOptionString[]
}

type SortByOptions = 'name' | 'group' | 'template'

function InvestmentTemplatesPortfolioList({
  portfolios,
  statusOptions,
  groupOptions,
  templateOptions,
  handleApplyTemplate,
  handleRemoveTemplate,
  selectedPortfolios,
  setSelectedPortfolios,
}: Props) {
  const [search, setSearch] = React.useState('')
  const [sortField, setSortField] = React.useState<SortByOptions>('name')
  const [selectedStatusOption, setSelectedStatusOption] =
    React.useState<StatusFilterOptions>('')
  const [selectedGroupOption, setSelectedGroupOption] =
    React.useState<string>('')
  const [selectedTemplateOption, setSelectedTemplateOption] =
    React.useState<string>('')
  const [portfolioPaginationList, setPortfolioPaginationList] =
    useState<PortfolioSummary[]>(portfolios)
  const [selectedAllPortfolioList, setSelectedAllPortfolioList] =
    useState<PortfolioSummary[]>(portfolios)
  const [page, setPage] = useState(1)
  const [portfoliosCount, setPortfoliosCount] = useState(portfolios.length)
  const [selectedItemsPerPageOption, setSelectedItemsPerPageOption] = useState(
    DEFAULT_ITEMS_PER_PAGE_OPTIONS[0]
  )

  useEffect(() => {
    const filteredPortfolios = portfolios.filter((portfolio) =>
      searchFilter(portfolio, 'displayName', search)
    )
    setPortfolioPaginationList(filteredPortfolios)
  }, [search, portfolios])

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  useEffect(() => {
    if (selectedGroupOption || selectedTemplateOption || selectedStatusOption) {
      setSelectedPortfolios([])
    }
  }, [
    selectedGroupOption,
    selectedStatusOption,
    selectedTemplateOption,
    setSelectedPortfolios,
  ])

  useEffect(() => {
    let clonedPortfolios = jsonClone(portfolios) as PortfolioSummary[]

    switch (sortField) {
      case 'name':
        clonedPortfolios = orderBy(clonedPortfolios, 'displayName')
        break
      case 'group':
        clonedPortfolios = orderBy(clonedPortfolios, 'groupName')
        break
      case 'template':
        clonedPortfolios = orderBy(clonedPortfolios, 'templateName')
        break
    }

    if (selectedStatusOption) {
      if (selectedStatusOption === 'disconnected') {
        clonedPortfolios = clonedPortfolios.filter((portfolio) =>
          getIsAccountDisconnected(portfolio?.accountStatus)
        )
      } else {
        const isSettingsCompleteStatusSelected =
          selectedStatusOption === 'complete'
        clonedPortfolios = clonedPortfolios
          .filter(
            // Remove disconnected accounts
            (portfolio) => !getIsAccountDisconnected(portfolio.accountStatus)
          )
          .filter(
            (portfolio) =>
              portfolio.isInvestmentPreferenceCompleted ===
              isSettingsCompleteStatusSelected
          )
      }
    }

    if (selectedGroupOption) {
      clonedPortfolios = clonedPortfolios.filter((portfolio) =>
        searchFilter(portfolio, 'groupName', selectedGroupOption)
      )
    }

    if (selectedTemplateOption) {
      clonedPortfolios = clonedPortfolios.filter((portfolio) =>
        searchFilter(portfolio, 'templateName', selectedTemplateOption)
      )
    }

    const filteredPortfolios = clonedPortfolios.filter((portfolio) =>
      searchFilter(portfolio, 'displayName', search)
    )
    setSelectedAllPortfolioList(filteredPortfolios)

    const portfoliosToShow = filteredPortfolios.slice(
      (page - 1) * selectedItemsPerPageOption.value,
      page * selectedItemsPerPageOption.value
    )
    setPortfolioPaginationList(portfoliosToShow)
    setPortfoliosCount(filteredPortfolios.length)
  }, [
    search,
    selectedGroupOption,
    selectedStatusOption,
    selectedTemplateOption,
    sortField,
    portfolios,
    page,
    selectedItemsPerPageOption,
  ])

  const itemsPerPageOptions = DEFAULT_ITEMS_PER_PAGE_OPTIONS.concat({
    label: 'See All',
    value: portfoliosCount,
  })

  const handleCheckboxChange = (newPortfolio: ChecklistOption) => {
    const updatedSelectedPortfolios = [...selectedPortfolios]
    const index = updatedSelectedPortfolios.findIndex(
      (portfolio) => portfolio.value === newPortfolio.value
    )
    if (index !== -1) {
      updatedSelectedPortfolios.splice(index, 1)
    } else {
      updatedSelectedPortfolios.push(newPortfolio)
    }
    setSelectedPortfolios(updatedSelectedPortfolios)
  }

  return (
    <>
      <Card
        display="flex"
        flexDirection="column"
        width="100%"
        justifyContent="space-between"
        padding={`${SPACE.sp20} ${SPACE.sp24}`}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={SPACE.sp24}
          height="100%"
          marginTop={SPACE.sp8}
        >
          <Box display="flex" flexDirection="column" gap={SPACE.sp12}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box>
                <Text
                  color={COLOR.coolGray600}
                  fontSize={FONT_SIZE.fs20}
                  lineHeight="xl"
                  fontWeight="700"
                >
                  Portfolios
                  <Text
                    color={COLOR.coolGray600}
                    fontSize={FONT_SIZE.fs20}
                    lineHeight="xl"
                    fontWeight="400"
                  >
                    &nbsp;({portfoliosCount})
                  </Text>
                </Text>
              </Box>
              <FlexBox gap={SPACE.sp6}>
                <FlexBox marginTop={SPACE.sp4} width="5.5rem">
                  {selectedPortfolios.length > 0 ? (
                    <Button
                      variant="tertiary"
                      size="xs"
                      onClick={() => setSelectedPortfolios([])}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Deselect All
                    </Button>
                  ) : (
                    <Button
                      variant="tertiary"
                      size="xs"
                      onClick={() =>
                        setSelectedPortfolios(
                          selectedAllPortfolioList.map((portfolio) => {
                            return {
                              label: portfolio.displayName,
                              value: portfolio.id,
                            }
                          })
                        )
                      }
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Select All
                    </Button>
                  )}
                </FlexBox>
                <FlexBox>
                  <Dropdown
                    options={statusOptions}
                    onChange={(option: any) => {
                      setSelectedStatusOption(option.value)
                      setPage(1)
                    }}
                    value={selectedStatusOption}
                    styles={{
                      control: (base: any) => ({
                        ...base,
                        width: '8.75rem',
                        borderRadius: '0.5rem',
                        height: '2.5rem',
                      }),
                    }}
                  />
                </FlexBox>
                <FlexBox>
                  <Dropdown
                    options={templateOptions}
                    onChange={(option: any) => {
                      setSelectedTemplateOption(option.value)
                      setPage(1)
                    }}
                    value={selectedTemplateOption}
                    styles={{
                      control: (base: any) => ({
                        ...base,
                        width: '9.5rem',
                        borderRadius: '0.5rem',
                        height: '2.5rem',
                      }),
                    }}
                  />
                </FlexBox>
                <FlexBox>
                  <Dropdown
                    options={groupOptions}
                    onChange={(option: any) => {
                      setSelectedGroupOption(option.value)
                      setPage(1)
                    }}
                    value={selectedGroupOption}
                    styles={{
                      control: (base: any) => ({
                        ...base,
                        width: '8.25rem',
                        borderRadius: '0.5rem',
                        height: '2.5rem',
                      }),
                    }}
                  />
                </FlexBox>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={handleApplyTemplate}
                  disabled={!selectedPortfolios.length}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Apply Template
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={handleRemoveTemplate}
                  disabled={!selectedPortfolios.length}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Remove Template
                </Button>
              </FlexBox>
            </Box>
            <Box
              width="100%"
              display="grid"
              gridTemplateColumns="3fr 12fr 3fr"
              gap={SPACE.sp12}
            >
              <Dropdown
                options={itemsPerPageOptions}
                onChange={(option: any) => {
                  setSelectedItemsPerPageOption(option)
                  setPage(1)
                }}
                value={selectedItemsPerPageOption}
                isSearchable={false}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    borderRadius: '0.5rem',
                    height: '2.5rem',
                  }),
                }}
              />
              <Box width="100%">
                <SearchBar
                  placeholder="Search by portfolio name"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                    setPage(1)
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="end" alignItems="center">
                <Text
                  color={COLOR.coolGray500}
                  fontSize={FONT_SIZE.fs12}
                  lineHeight="xs"
                  fontWeight="bold"
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Sort by:
                </Text>
                <Button
                  variant="tertiary"
                  size="xs"
                  active={sortField === 'name'}
                  onClick={() => setSortField('name')}
                >
                  Name
                </Button>
                <Button
                  variant="tertiary"
                  size="xs"
                  active={sortField === 'group'}
                  onClick={() => setSortField('group')}
                >
                  Group
                </Button>
                <Button
                  variant="tertiary"
                  size="xs"
                  active={sortField === 'template'}
                  onClick={() => setSortField('template')}
                >
                  Template
                </Button>
              </Box>
            </Box>
          </Box>
          <ListView
            gap={SPACE.sp12}
            data={portfolioPaginationList}
            renderItem={(item: PortfolioSummary) => (
              <InvestmentTemplatesPortfolioListItem
                key={item.id}
                {...item}
                handleCheckboxChange={handleCheckboxChange}
                isChecked={
                  selectedPortfolios.findIndex(
                    (portfolio) => portfolio.value === item.id
                  ) !== -1
                }
              />
            )}
          />
        </Box>
        <Box marginTop={SPACE.sp12}>
          <Pagination
            currentPage={page}
            handlePageChange={handlePageChange}
            itemsCount={portfoliosCount}
            itemsPerPage={selectedItemsPerPageOption.value}
          />
        </Box>
      </Card>
    </>
  )
}

export default InvestmentTemplatesPortfolioList
