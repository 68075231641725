import { Text } from 'components/common'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'

type Props = {
  text: string
  url: string
}

export function TextLink({ text, url }: Props) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Text fontSize={FONT_SIZE.fs12} fontWeight={600} color={COLOR.purple600}>
        {text}
      </Text>
    </a>
  )
}
