import { ArrowRightIcon } from '@heroicons/react/solid'
import { capitalize, round } from 'lodash'

import { Asset, PortfolioSuggestedTrade, TRADE_ACTION } from 'types'

import { Tag, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { formatAsCurrency } from 'utils'
import { roundToHundredths } from 'utils/numberUtils'

import { ViewType } from './SuggestedTradesList'

type Props = {
  asset: Asset
  trade: PortfolioSuggestedTrade
  onAssetClick: (symbol: string) => void
  viewType: ViewType
}

function SuggestedTradeItem({ asset, trade, onAssetClick, viewType }: Props) {
  const {
    symbol,
    shares,
    action,
    currentWeight = 0,
    recommendedWeight = 0,
    value = 0,
    realizedGain = 0,
  } = trade
  const currentWeightPercentage = round(currentWeight * 100, 2)
  const recommendedWeightPercentage = round(recommendedWeight * 100, 2)

  const absoluteRelativeGain = formatAsCurrency(Math.abs(realizedGain))
  let realizedGainColor = COLOR.coolGray600
  let realizedGainLabel = 'No Gain'
  if (realizedGain < 0) {
    realizedGainColor = COLOR.emerald600
    realizedGainLabel = `${absoluteRelativeGain} Loss`
  }
  if (realizedGain > 0) {
    realizedGainColor = COLOR.red600
    realizedGainLabel = `${absoluteRelativeGain} Gain`
  }

  return (
    <FlexBox
      flexDirection="row"
      flexBasis="calc(50% - 0.5rem)"
      alignItems="center"
      padding={`${SPACE.sp8} ${SPACE.sp12}`}
      justifyContent="space-between"
      gap={SPACE.sp4}
      border="1px solid"
      borderColor={COLOR.coolGray300}
      borderRadius={SPACE.sp8}
      width="100%"
      height="3.5rem"
      onClick={() => onAssetClick(symbol)}
      style={{ cursor: 'pointer' }}
    >
      <FlexBox flex={2} gap={SPACE.sp8} alignItems="center">
        <Tag size="sm">{symbol}</Tag>
        <Text
          fontWeight="medium"
          fontSize={FONT_SIZE.fs12}
          lineHeight="xs"
          color={COLOR.coolGray600}
        >
          {asset?.name}
        </Text>
      </FlexBox>
      {viewType === 'percentage' && (
        <FlexBox
          flex={1}
          justifyContent="flex-end"
          alignItems="center"
          gap={SPACE.sp4}
        >
          <Tag variant="white" size="sm">
            {' '}
            <Text
              fontSize={FONT_SIZE.fs10}
              fontWeight="bold"
            >{`${roundToHundredths(currentWeightPercentage)}%`}</Text>
          </Tag>
          <ArrowRightIcon width={12} height={12} />
          <Tag
            variant={action === TRADE_ACTION.BUY ? 'green' : 'red'}
            size="sm"
          >
            <Text fontSize={FONT_SIZE.fs10} fontWeight="bold">
              {`${roundToHundredths(recommendedWeightPercentage)}%`}
            </Text>
          </Tag>
        </FlexBox>
      )}
      {viewType === 'shares' && (
        <FlexBox flex={1} justifyContent="flex-end">
          <Text
            fontWeight="bold"
            fontSize={FONT_SIZE.fs12}
            lineHeight="xs"
            color={COLOR.coolGray600}
          >
            {`${capitalize(action)} ${roundToHundredths(shares)} shares`}
          </Text>
        </FlexBox>
      )}
      {viewType === 'value' && (
        <FlexBox flex={1} justifyContent="flex-end">
          <Text
            fontWeight="bold"
            fontSize={FONT_SIZE.fs12}
            lineHeight="xs"
            color={COLOR.coolGray600}
          >
            {`${capitalize(action)} ${formatAsCurrency(value)}`}
          </Text>
        </FlexBox>
      )}
      {viewType === 'gain' && (
        <FlexBox flex={1} justifyContent="flex-end">
          <Text
            fontWeight="bold"
            fontSize={FONT_SIZE.fs12}
            lineHeight="xs"
            color={realizedGainColor}
          >
            {realizedGainLabel}
          </Text>
        </FlexBox>
      )}
    </FlexBox>
  )
}

export default SuggestedTradeItem
