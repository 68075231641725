import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useState } from 'react'
import { NumberFormatValues, NumericFormat } from 'react-number-format'

import { Portfolio } from 'types'

import { paveApi } from 'api'
import { Box, Button, Text, TextInput } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { useCustomToast } from 'hooks/useCustomToast'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { getPortfolioWithdrawalPayload } from 'utils/portfolioFormUtil'
import { buildUpdatePreferencesRequest } from 'utils/portfolioUtil'

import ModalLoader from '../ModalLoader'

type Props = {
  isOpen: boolean
  onClose: () => void
  portfolio: Portfolio
}

function InitiateWithdrawalModal({ isOpen, onClose, portfolio }: Props) {
  const { showErrorToast, showSuccessToast } = useCustomToast()
  const [withdrawalAmount, setWithdrawalAmount] = useState(0)

  const [editPortfolioPreferences, { isLoading }] =
    paveApi.useEditPortfolioPreferencesMutation()

  const handleConfirm = () => {
    if (withdrawalAmount) {
      let portfolioPayload = getPortfolioWithdrawalPayload(
        portfolio,
        withdrawalAmount
      )
      const updatePreferencesRequest = buildUpdatePreferencesRequest(
        portfolio,
        portfolioPayload
      )
      editPortfolioPreferences(updatePreferencesRequest)
        .unwrap()
        .then(() => {
          showSuccessToast('Successfully initiated withdrawal!')
          onClose()
        })
        .catch((e: any) => {
          const { error, errorDescription, errorMessage } = e?.data ?? {}

          if (error) {
            showErrorToast(errorMessage, errorDescription)
          } else {
            showErrorToast('An error occurred when creating the withdrawal.')
          }
        })
    }
  }

  const handleWithdrawalAmountChange = (v: NumberFormatValues) => {
    const value: number = typeof v.floatValue === 'number' ? v.floatValue : 0
    setWithdrawalAmount(value)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      size="lg"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.2)" />
      <ModalContent>
        <ModalHeader>
          <Box>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs24}>
              Cash Withdrawal
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingX={SPACE.sp24}>
          <FlexBox flexDirection="column" gap={SPACE.sp12}>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
              Please enter the amount of cash you would like to withdraw from
              this portfolio. Pave Pro will then generate new suggested trades
              to liquidate the desired amount.
            </Text>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
              If you have Automation on, your trades will automatically be
              placed the following trading day. If Automation is off, you will
              have to manually approve the trades before they are placed.
            </Text>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
              Once trades have been placed, you will need to manually carry out
              the withdrawal through your custodian. Once you have done so,
              please mark the withdrawal as complete within Pave Pro.
            </Text>
            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs14}
              fontWeight={500}
            >
              This action may trigger a wash sale as your last set of trades may
              have been placed within the last 30 days. Wash sales relate to
              realizing losses and trading the same security within a 30 day
              window. Wash sales will be listed on a brokerage statement and may
              be disclosed to the IRS. Please contact a tax adviser for
              guidance.
            </Text>
            <NumericFormat
              label="Withdrawal Amount"
              allowNegative={false}
              allowLeadingZeros={false}
              placeholder="$0.00"
              customInput={TextInput}
              value={withdrawalAmount}
              onValueChange={handleWithdrawalAmountChange}
              displayType="input"
              thousandSeparator={true}
              decimalScale={2}
              prefix="$"
            />
          </FlexBox>
        </ModalBody>
        <ModalFooter marginBottom={SPACE.sp8}>
          <FlexBox
            flexDirection="column"
            gap={SPACE.sp8}
            marginBottom={SPACE.sp12}
            width="100%"
          >
            <Button
              onClick={handleConfirm}
              disabled={withdrawalAmount === 0.0}
              size="md"
              variant="primary"
              width="100%"
            >
              Initiate Withdrawal
            </Button>
            {isLoading && <ModalLoader />}
          </FlexBox>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export default InitiateWithdrawalModal
