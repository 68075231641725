import { Form, Formik } from 'formik'
import { useState } from 'react'
import validator from 'validator'

import { Button, Checkbox, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { TextLink } from 'components/common/TextLink/TextLink'
import { MSLA_URL, TERMS_OF_SERVICE_URL } from 'constants/general.constants'
import { useCaseOptions } from 'constants/signup.constants'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import {
  AccountDetailsFormFields,
  AccountPrimaryContactDetails,
  accountDetailsInitialValues,
} from './AccountDetailsFormFields'
import { Dropdown } from './Fields'
import { FormContainer } from './shared'

type Props = {
  email: string
  isButtonDisabled: boolean
  onSubmit: (values: AccountPrimaryContactDetails) => void
}
function CompleteProfileForm({ email, isButtonDisabled, onSubmit }: Props) {
  const initialValues = { ...accountDetailsInitialValues, email: email }
  const [isTosChecked, setIsTosChecked] = useState(false)
  const [isMslaChecked, setIsMslaChecked] = useState(false)

  return (
    <FormContainer>
      <Formik
        initialValues={initialValues}
        validate={(values: AccountPrimaryContactDetails) => {
          const errors: Partial<AccountPrimaryContactDetails> = {}
          if (validator.isEmpty(values.firstName)) {
            errors.firstName =
              'First name is required and must only contain letters'
          }
          if (validator.isEmpty(values.lastName)) {
            errors.lastName =
              'Last name is required and must only contain letters'
          }
          if (validator.isEmpty(values.country)) {
            errors.country = 'Country is required and must only contain letters'
          }
          if (validator.isEmpty(values.state)) {
            errors.state = 'State is required and must only contain letters'
          }
          if (validator.isEmpty(values.zipCode)) {
            errors.zipCode =
              'Zip code is required and must only contain letters'
          }
          return errors
        }}
        onSubmit={(values: AccountPrimaryContactDetails) => {
          onSubmit(values)
        }}
      >
        <Form>
          <FlexBox
            alignItems="center"
            flexDirection="column"
            gap={SPACE.sp24}
            width="34rem"
          >
            <Text
              fontSize="xl"
              fontWeight="bold"
              lineHeight="xl"
              textAlign="center"
              color="black"
            >
              Create your Pave Pro account
            </Text>
            <FlexBox flexDirection="column" gap={SPACE.sp16}>
              <AccountDetailsFormFields disableEmail />
              <Dropdown
                name="useCase"
                label="Which case best describes you?"
                options={useCaseOptions}
                placeholder="Select a use case"
              />
              <FlexBox gap={SPACE.sp8} alignItems="center">
                <Checkbox
                  width="1rem"
                  height="1rem"
                  onClick={() => {
                    setIsTosChecked(!isTosChecked)
                  }}
                  checked={isTosChecked}
                />
                <Text
                  fontSize={FONT_SIZE.fs12}
                  fontWeight={400}
                  lineHeight="xs"
                  color={COLOR.coolGray600}
                >
                  I have read and accepted the{' '}
                  <TextLink
                    text="terms of service"
                    url={TERMS_OF_SERVICE_URL}
                  />
                </Text>
              </FlexBox>
              <FlexBox gap={SPACE.sp8} alignItems="center">
                <Checkbox
                  width="1rem"
                  height="1rem"
                  onClick={() => {
                    setIsMslaChecked(!isMslaChecked)
                  }}
                  checked={isMslaChecked}
                />
                <Text
                  fontSize={FONT_SIZE.fs12}
                  fontWeight={400}
                  lineHeight="xs"
                  color={COLOR.coolGray600}
                >
                  I have read and accepted the{' '}
                  <TextLink text="MSLA" url={MSLA_URL} />
                </Text>
              </FlexBox>
              <Button
                disabled={isButtonDisabled || !isTosChecked || !isMslaChecked}
                size="lg"
                type="submit"
                variant="primary"
              >
                Create Account
              </Button>
            </FlexBox>
          </FlexBox>
        </Form>
      </Formik>
    </FormContainer>
  )
}
export default CompleteProfileForm
