import styled from '@emotion/styled'
import { isNumber, round } from 'lodash'

import { GetPortfolioStatsResponse } from 'types'

import { Box, Button, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { coolGray } from 'styles/system/colors'
import { formatAsCurrency } from 'utils'

import StatItem from '../StatItem'

type Props = {
  statistics?: GetPortfolioStatsResponse
  isLoading?: boolean
  inactive?: boolean
  isThirdPartyManaged?: boolean
  hasSuggestedTrades?: boolean
  editPortfolio?: () => void
  totalRealizedGain?: number
  portfolioNeedsTradeExecution?: boolean
}

function PortfolioStats({
  statistics,
  isLoading,
  inactive,
  isThirdPartyManaged,
  editPortfolio,
  totalRealizedGain,
  portfolioNeedsTradeExecution,
  hasSuggestedTrades,
}: Props) {
  if (!statistics?.current || !statistics?.recommended) {
    if (isLoading) {
      // Handle loading statistics
      return (
        <Container>
          <Box width="100%" height="100%" borderRadius="8px">
            <Text
              fontWeight="medium"
              fontSize="xs"
              lineHeight="xs"
              color="coolGray.700"
            >
              Loading statistics...
            </Text>
          </Box>
        </Container>
      )
    } else {
      // Handle undefined statistics
      return null
    }
  }

  const {
    returnPotential: recommendedPortfolioReturnPercentile,
    relativeRisk: recommendedPortfolioRelativeRisk,
  } = statistics.recommended

  const {
    returnPotential: currentPortfolioReturnPotential,
    relativeRisk: currentPortfolioRelativeRisk,
  } = statistics.current

  const relativeRisk = round(
    recommendedPortfolioRelativeRisk - currentPortfolioRelativeRisk,
    1
  )
  const returnPotential = round(
    recommendedPortfolioReturnPercentile - currentPortfolioReturnPotential,
    1
  )

  return (
    <Container>
      {isThirdPartyManaged ? (
        <Box
          background={coolGray['50']}
          width="100%"
          height="100%"
          borderRadius="8px"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            margin="0 48px"
            gap="8px"
          >
            <Box display="flex" flexDirection="column">
              <Text fontSize="sm">
                Suggested trades are not available for portfolios managed by a
                third party.
              </Text>
              <Text fontSize="sm">
                If you would like us to provide suggestions, please edit the
                portfolio settings.
              </Text>
            </Box>
            <Button size="xs" onClick={editPortfolio}>
              Edit Portfolio Settings
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          {portfolioNeedsTradeExecution && (
            <Text
              fontWeight="medium"
              fontSize="xs"
              lineHeight="xs"
              color="coolGray.700"
            >
              Trade execution is in progress. During this time your investment
              preferences are frozen and you will not be able to execute
              additional trades.
            </Text>
          )}
          <Text
            fontWeight="medium"
            fontSize="xs"
            lineHeight="xs"
            color="coolGray.700"
          >
            {!hasSuggestedTrades ? (
              <>
                Currently there are no suggested trades. Your portfolio is up to
                date
              </>
            ) : (
              <>
                {' '}
                Executing suggested trades will have the following impact on
                this portfolio:
              </>
            )}
          </Text>

          {hasSuggestedTrades && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              padding="0px"
              gap="24px"
              width="100%"
            >
              <StatItem
                value={returnPotential}
                label="Return Potential"
                inactive={inactive || isLoading}
              />
              <StatItem
                value={relativeRisk}
                label="Relative Risk"
                reversed
                inactive={inactive || isLoading}
              />
              {isNumber(totalRealizedGain) && (
                <FlexBox
                  flexDirection="column"
                  gap={SPACE.sp16}
                  marginTop={SPACE.sp18}
                  alignItems="center"
                >
                  <Text fontWeight="bold" fontSize={FONT_SIZE.fs16}>
                    {formatAsCurrency(totalRealizedGain)}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={FONT_SIZE.fs12}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    Realized Gain
                  </Text>
                </FlexBox>
              )}
            </Box>
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export default PortfolioStats
