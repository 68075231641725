import { AssetClass, TradeStatus } from 'enums'
import { BenchmarkType } from 'enums'
import { PerformancePriority } from 'enums/PerformancePriorityEnum'
import { SuggestedTradesStatus } from 'enums/SuggestedTradesStatusEnum'
import { FactorTiltObject } from 'types/generalTypes'
import { Strategies } from 'types/portfolioTypes'

export type ToastType = 'Error' | 'Success' | 'Info'

export type Account = {
  id: number
  name: string
  balance: number
  gainPercentage: number
  portfolios: number
  address: string
  phoneNumber: string
  email: string
  clients: any[]
}

export type Portfolio = {
  assets: Asset[]
  availableCash: number
  benchmark?: Benchmark
  benchmarkId: string | null
  buyListId: string | null
  brokerageAccounts: BrokerageAccount[]
  clientAccountId: string
  displayName: string
  gainPercentage: number
  investmentPreference?: InvestmentPreference
  investmentPreferencesTemplate?: InvestmentTemplate
  investmentPreferencesTemplateId: string | null
  id: string
  isInvestmentPreferenceCompleted?: boolean
  isThirdPartyManaged?: boolean
  isTrading: boolean
  needsTradeExecution: boolean
  paveSuggestedTrades?: any
  performance: number
  recommendedAssets?: { [key: string]: any }
  suggestedTrades: PortfolioSuggestedTrade[]
  suggestedTradesStatus: SuggestedTradesStatus
  statistics: any
  totalRealizedGain?: number
  trades: Trade[]
  unsupportedAssets: Asset[]
  userApprovedAutomatedTradesOnce: boolean
  value: number
  lastTradedAt?: string
  createdAt: string
  updatedAt: string
  tradingStatus?: TradingStatus | null
  accountStatus?: ACCOUNT_STATUS
}

export type PortfolioSummary = Portfolio & {
  groupName?: string
  groupId?: string
  templateName?: string
  automation?: boolean
  accountStatus?: ACCOUNT_STATUS
}

export type InvestmentPreference = {
  automation: boolean
  fractionalShares: boolean
  targetAssetCount: number
  targetCashWeight?: number | null
  excludedAssetClasses: string[]
  excludedAssets: string[]
  excludedIndustries: string[]
  excludedSectors: string[]
  enableTaxOptimization?: boolean
  performancePriority?: PerformancePriority
  factorTilts?: FactorTiltObject[]
  withdrawalCashRequest?: WithdrawalCashRequestDto
  assetClass?: AssetClass
  activeConnectedAccountIds?: string[]
  multiAssetClassStrategy?: Strategies
}

export type WithdrawalCashRequestDto = {
  cashAmount: number
  createdAt: string
}

export type PartialInvestmentPreference = {
  automation?: boolean | null
  fractionalShares?: boolean | null
  targetAssetCount?: number | null
  targetCashWeight?: number | null
  excludedAssetClasses?: string[] | null
  excludedAssets?: string[] | null
  excludedIndustries?: string[] | null
  excludedSectors?: string[] | null
  enableTaxOptimization?: boolean | null
  performancePriority?: PerformancePriority | null
  factorTilts?: FactorTiltObject[] | null
  assetClass?: AssetClass | null
  multiAssetClassStrategy?: Strategies
}

export type BuyList = {
  id: string
  displayName: string
  assets: BuyListAsset[]
}

export type BuyListAsset = {
  symbol: string
  isSupported: boolean
  name?: string
}

export interface BuyListSummaryBasic {
  id: string
  displayName: string
  assetCount: number
}

export interface BuyListSummaryOwner extends BuyListSummaryBasic {
  portfolioCount: number
  userCount: number
}

export type PortfolioSuggestedTrade = {
  symbol: string
  isClose: boolean
  shares: number
  action: TRADE_ACTION
  currentWeight?: number
  realizedGain?: number
  recommendedWeight?: number
  weightDiff?: number
  value?: number
}

export enum TRADE_ACTION {
  BUY = 'buy',
  SELL = 'sell',
}

export enum PortfolioLinkType {
  InteractiveBrokers = 'InteractiveBrokers',
  PaveSecurities = 'Pave Securities',
  CharlesSchwab = 'Charles Schwab',
  Plaid = 'Plaid',
  None = 'None',
}

export type InteractiveBrokersAccountInfo = {
  userID: string
  username: string
  accountNumber: string
  accountStatus: IBAccountStatus
  accountState?: IBAccountState
}

export enum PLAID_ACCOUNT_STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum ACCOUNT_STATUS {
  ABANDONED = 'ABANDONED',
  CLOSED = 'CLOSED',
  DISCONNECTED = 'DISCONNECTED',
  NEW = 'NEW',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum PLAID_ACCOUNT_ERROR_CODE {
  ITEM_LOGIN_REQUIRED = 'ITEM_LOGIN_REQUIRED',
}

export type PlaidAccountInfo = {
  accessToken: string
  itemId: string
  activeConnectedAccountIds?: string[]
  status?: PLAID_ACCOUNT_STATUS
  errorCode?: PLAID_ACCOUNT_ERROR_CODE
  errorMessage?: string
}

export type SubAccountInfo = {
  addressLine1: string
  addressLine2: string
  addressLine3: string
  city: string
  country: string
  email: string
  firstName: string
  lastName: string
  mainAccountNumber: string
  middleName: string
  phoneNumber: string
  state: string
  subAccountNumber: string
  zipCode: string
}

export type InvestmentTemplate = {
  automation?: boolean
  benchmarkId: string | null
  buyListId: string | null
  displayName: string
  id: string
  investmentPreferences: PartialInvestmentPreference
  portfoliosCount: number
}

export type GetInvestmentTemplatesResponse = {
  investmentPreferencesTemplates: InvestmentTemplate[]
  count: number
}

export type AccountInfo = {
  status?: ACCOUNT_STATUS
  accountNumber: string
}

export enum CONNECTION_TYPE {
  IB = 'IB',
  SCHWAB = 'SCHWAB',
  PLAID = 'PLAID',
}

export type BrokerageAccount = {
  id: string
  brokerName: string
  brokerLogo?: string
  connectionType?: CONNECTION_TYPE
  ibAccountInfo?: InteractiveBrokersAccountInfo
  plaidAccountInfo?: PlaidAccountInfo
  accountInfo?: AccountInfo
  portfolioId: string
}

export type Sector = {
  id: string
  code: string
  name: string
  industries: Industry[]
}
export type Industry = {
  id: string
  code: string
  name: string
  sector: Sector
  sectorId: string
}
export enum ASSET_SUPPORTED_TYPE {
  ALL = 'ALL',
  MONEY_MARKET = 'MONEY_MARKET',
}
export enum ASSET_LIST_TYPE {
  UNSUPPORTED = 'UNSUPPORTED',
  SUPPORTED = 'SUPPORTED',
  MONEY_MARKET = 'MONEY_MARKET',
}
export type Asset = {
  id: string
  averageCost: number
  class?: string
  country: string
  industry: Industry
  industryId: string
  isSupported: boolean
  name: string
  percentageOfPortfolio: number
  price: number
  priceDetails: {
    open: number
    close: number
    dayGain: number
  }
  returnPercentile: number | null
  returnRating: number
  riskPercentile: number | null
  riskRating: number
  shares: number
  supportedType: ASSET_SUPPORTED_TYPE
  symbol: string
  totalValue: number
  tradablePercentage: number
}

export type AssetOverviewInfo = {
  asset: Asset
  description?: string
  type?: string
}

export type AssetSymbolWithWeight = {
  symbol: string
  weight: number
}

export type PortfolioAsset = {
  symbol: string
  shares: number
  tradablePercentage: number
  averageCost?: number
}

export type Trade = {
  createdAt: string
  updatedAt: string
  id: string
  portfolioId: string
  assetSymbol: string
  action: TRADE_ACTION
  isLatest: boolean
  isClose: boolean
  ibOrderStatus: TradeStatus
  ibAccountNumber: string
  ibOrderId?: string
  ibOrderMessage?: string[]
  ibConid?: number
  quantity?: number
  costBasis?: number
  cashQuantity?: number
  suggestedTrade?: PortfolioSuggestedTrade
  brokerAccountNumber?: string
  mainAccountNumber?: string
  status?: TradeStatus
}

export type Benchmark = {
  id: string
  code: string
  name: string
  returnPercentile?: number
  riskPercentile?: number
  type: BenchmarkType
}

export type ReducerAction = { type: string; payload?: any }

/*
 A=Abandoned (i.e. application was deleted)
 N=New Account(Account is not yet open and no funding details have been provided.)
 O=Open (Considered active accounts)
 C=Closed (considered accounts that were once active OR open accounts that were and then closed.)
 P=Pending Application, funding details have been provided.
 R=Rejected (meaning account was never approved/opened- rejected by Compliance)
*/
export type IBAccountStatus = 'A' | 'N' | 'C' | 'O' | 'P' | 'R'
/*
  State of the application, only present if status is N(New Account) or P(Pending)
  Incomplete Application=Client Action Needed
  Documents Required=Client Action Needed
  Under Review with IBKR=Application is PENDING on IBKR, no action needed.
  Pending Approval=Account is in the approval queue and should be opened by the
  following business day.
*/
export type IBAccountState =
  | 'Incomplete Application'
  | 'Documents Required'
  | 'Under Review with IBKR'
  | 'Pending Approval'
  | 'Approved'

export * from './SignInResponse'
export * from './portfolioTypes'

export enum AllocationFilter {
  Value = 'VALUE',
  Sector = 'SECTOR',
  Industry = 'INDUSTRY',
  Geography = 'GEOGRAPHY',
}

export type Allocation = {
  name: AllocationFilter
  values: string[]
  labels: string[]
}

export enum TradingStatus {
  NEEDS_TRADE_EXECUTION_AUTOMATION_REQUEST = 'NEEDS_TRADE_EXECUTION_AUTOMATION_REQUEST',
  NEEDS_TRADE_EXECUTION_USER_REQUEST = 'NEEDS_TRADE_EXECUTION_USER_REQUEST',
  NEEDS_TO_BE_MARKED_FOR_TRADING = 'NEEDS_TO_BE_MARKED_FOR_TRADING',
  NEEDS_TO_BE_MARKED_FOR_TRADING_USER_REQUEST = 'NEEDS_TO_BE_MARKED_FOR_TRADING_USER_REQUEST',
}

export enum STATUS_ENUM {
  SUCCESS = 'Success',
  ERROR = 'Error',
}

export interface StatusResponseDto {
  status: STATUS_ENUM
  error?: string
  errorMessage?: string
}

export enum InvestmentPreferenceAction {
  EDIT = 'EDIT',
  EDIT_AND_PATCH_AUTOMATION = 'EDIT_AND_PATCH_AUTOMATION',
  PATCH_AUTOMATION = 'PATCH_AUTOMATION',
  PATCH_TEMPLATE_ID = 'PATCH_TEMPLATE_ID',
  PATCH_AUTOMATION_AND_TEMPLATE_ID = 'PATCH_AUTOMATION_AND_TEMPLATE_ID',
  NO_ACTION = 'NO_ACTION',
}

export enum TradePreference {
  NOW = 'NOW',
  MONTHLY_CADENCE = 'MONTHLY_CADENCE',
}
