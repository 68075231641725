import { GenericObject } from 'types/generalTypes'

/**
 * Checks if the input is a null or undefined
 */
export const isNullish = (input: any): boolean => {
  return input === null || input === undefined
}

/**
 * Duplicates an input by coercing it into a string and back
 * This is used to deep clone non-circular objects
 */
export function jsonClone<T>(input: T): T {
  const jsonString = JSON.stringify(input)
  return JSON.parse(jsonString)
}

/**
 * Creates a new object with all null or undefined values removed
 */
export const prune = (input: GenericObject): GenericObject => {
  const output = jsonClone(input)

  for (const key of Object.keys(output)) {
    if (output[key] === null || output[key] === undefined) {
      delete output[key]
    }
  }

  return output
}
