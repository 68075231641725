import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import { Box, Button, LinkText, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { SUPPORT_EMAIL, SUPPORT_MAILTO_LINK } from 'constants/general.constants'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  isAutomationOn?: boolean
  onClick: () => void
  onClose: () => void
  showModal: boolean
}

export default function ConfirmSaveFirstPreferencesModal({
  isAutomationOn,
  onClick,
  onClose,
  showModal,
}: Props) {
  return (
    <Modal isOpen={showModal} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs16}>
              {isAutomationOn
                ? 'Trade Portfolio Immediately?'
                : 'Save Investment Preferences?'}
            </Text>
          </Box>
        </ModalHeader>
        <ModalBody>
          {isAutomationOn ? (
            <FlexBox flexDirection="column" gap={SPACE.sp8}>
              <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
                By turning on automation and completing your investment
                preferences, you are agreeing to this portfolio’s suggested
                trades being placed the following trading day.
              </Text>
              <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
                If you are not happy with the list of suggested trades, you have
                until the end of the calendar day to edit the portfolio’s
                investment preferences and receive a new set of trades.
              </Text>
              <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
                If you would like to keep the portfolio from trading, please
                turn off automation. Pave will then remove the portfolio from
                the trading queue and wait for your approval before scheduling
                the portfolio to trade.
              </Text>
              <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
                If you have any questions, please reach out to{' '}
                <LinkText
                  href={SUPPORT_MAILTO_LINK}
                  label={SUPPORT_EMAIL}
                  bold
                />
              </Text>
            </FlexBox>
          ) : (
            <FlexBox flexDirection="column" gap={SPACE.sp8}>
              <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
                Saving this portfolio's investment preferences will trigger the
                generation of suggested trades.
              </Text>
              <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
                Since automation is off, you will be required to approve the
                suggested trades before they are placed by Pave.
              </Text>
            </FlexBox>
          )}
        </ModalBody>

        <ModalFooter marginBottom={SPACE.sp8}>
          <Box width="100%">
            <Box marginBottom={SPACE.sp12}>
              <Button
                onClick={onClick}
                size="md"
                variant="primary"
                width="100%"
              >
                Confirm
              </Button>
            </Box>

            <Button
              onClick={onClose}
              size="md"
              variant="secondary"
              width="100%"
            >
              Back
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
